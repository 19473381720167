// Pages
import Help from '../pages/help';
import Landing from '../pages/landing';
import Individual360Dashboard from '../pages/individual360dashboard';
import SelfReview from '../pages/self-review';
import Review from '../pages/review';
import Reviewers from '../pages/reviewers';
import FeedbackRequests from '../pages/feedbackrequests';

const pageRoutes = [
  {
    path: '/:accountID/:userID/',
    component: Landing,
    exact: true,
  },
  {
    path: '/:accountID/:userID/help/',
    component: Help,
    exact: true,
  },
  {
    path: '/:accountID/:userID/feedback-requests/',
    component: FeedbackRequests,
    exact: true,
  },
  {
    path: '/:accountID/:userID/360/:current360ID/:groupID/',
    component: Individual360Dashboard,
    exact: true,
  },
  {
    path: '/:accountID/:userID/360/:current360ID/:groupID/reviewers/',
    component: Reviewers,
    exact: true,
  },
  {
    path: '/:accountID/:userID/360/:current360ID/:groupID/:questionnaireID/',
    component: SelfReview,
    exact: true,
  },
  {
    path: '/:accountID/:userID/360/:current360ID/:groupID/:questionnaireID/goal/:goalID/',
    component: SelfReview,
    exact: true,
  },
  {
    path: '/:accountID/:current360ID/review/:reviewerID/',
    component: Review,
    exact: true,
  },
]


export default pageRoutes;
