import React, { Component } from 'react'
import Navigation from './navigation'
import logo from '../logo.svg'

let fName

class SiteHeader extends Component {
  constructor(props) {
    super(props)
    console.log(props)
    if(this.props.userDetails.forename) {
      fName = this.props.userDetails.forename
    } else {
      // For reviewers, split the name into F/L name (as it is just added to a single "name" entry in the DB)
      let reviewerName = this.props.userDetails.name
      reviewerName = reviewerName.split(' ').slice(0, -1)
      fName = reviewerName
    }
  }
  render() {
    return (
      <header className='site-header'>
        <div className='container'>
          <div className='site-header__row'>
            <p className='ma0 pa0 dn dib-l'>Hi,&nbsp; <strong>{fName}</strong> - {this.props.userDetails.email}</p>

            <p className='ma0 pa0 dn'>( { this.props.completionPercentage }% Complete )</p>
            <div className="header-logo"></div>
          </div>
        </div>
      </header>
    );
  }
}

export default SiteHeader;
