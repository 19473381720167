import React, { Component } from 'react';
import { db } from '../firebase';
import { Link } from 'react-router-dom';
import SiteHeaderReviewer from '../components/site-header-reviewer';
import SiteNav from '../components/site-nav';
import SiteFooterReviewer from '../components/site-footer-reviewer';
import Questionnaire from '../components/questionnaire';
import withBranding from '../components/withbranding';
import { projectTypeText, sleep } from '../helpers';
import loadingGif from '../loading.gif'
const authCondition = (authUser) => !!authUser;

class Review extends Component {
  constructor() {
    super();

    this.questionUpdated = this.questionUpdated.bind(this);
    this.updateCompletionPercentage = this.updateCompletionPercentage.bind(this);

    this.state = {
      userNotFound: false,
      reviewerID: null,
      accountID: null,
      reviewerDetails: null,
      questionnaireID: null,
      userDetails: null,
      completionPercentage: 0,
      questions: null,
      requiredQuestionsList: {},
      questionsLoaded: false,
      answers: null,
      answersLoaded: false,
      userID: false,
    };
  }
  componentDidMount() {
    const {
      match: { params },
    } = this.props;

    db.doGetCurrentReviewerRef(params.accountID, params.reviewerID).then((snapshot) => {
      if (!snapshot.data()) {
        this.setState({
          userNotFound: true,
        });
      } else {
        let reviewerDetails = snapshot.data();

        this.setState({
          accountID: params.accountID,
          reviewerID: params.reviewerID,
          reviewerDetails: reviewerDetails,
        });

        db.doGetCurrentReviewStatus(params.accountID, params.reviewerID).then((snapshot) => {
          this.setState({
            completionPercentage: snapshot.data()['completionPercentage'],
          });

          db.doGetCurrentUser(params.accountID, reviewerDetails.userID).then((snapshot) => {
            if (snapshot.data()) {
              this.setState({
                userDetails: snapshot.data(),
              });
            }

            db.doGetQuestionnaireType(params.accountID, reviewerDetails.questionnaireID).then((snapshot) => {
              let questionnaireType = 'self-review';
              if (snapshot.data()) {
                questionnaireType = snapshot.data()['type'];
              }

              db.doGetQuestionnaireSections(params.accountID, reviewerDetails.questionnaireID).then((snapshot) => {
                // console.log(snapshot);
                if (!snapshot.empty) {
                  let sections = {};
                  let sectionNum = 0;
                  let numSections = snapshot.docs.length;
                  snapshot.docs.map((doc) => {
                    let sectionID = doc.id;
                    sections[sectionID] = doc.data();

                    //
                    db.doGetQuestions(params.accountID, reviewerDetails.questionnaireID, sectionID).then((snapshot) => {
                      sectionNum++;
                      let questions = {};
                      let legacyMode = false;
                      if (sections[sectionID]) {
                        snapshot.docs.map((doc) => {
                          if (doc.data().key === undefined) {
                            let dataObj = new Object();
                            dataObj = doc.data();
                            dataObj.id = doc.id;
                            dataObj.legacyKey = doc.id;
                            questions[doc.id] = dataObj;
                          } else {
                            questions[doc.id] = doc.data();
                          }
                          // questions[doc.id] = doc.data();
                        });

                        const toOrder = Object.keys(questions).map((i) => questions[i]);
                        //it will always check the first one in the arrray which will always be the oldest as they are saved in date orderr
                        if (toOrder.length != 0) {
                          if (toOrder[0].sortOrder === undefined) {
                            legacyMode = true;
                          }
                        }

                        if (legacyMode === false) {
                          toOrder.sort((a, b) => {
                            if (a.sortOrder < b.sortOrder) {
                              return -1;
                            }
                            if (a.sortOrder > b.sortOrder) {
                              return 1;
                            }
                          });
                        }
                        let arrayToObj = {};
                        for (let i = 0; i < toOrder.length; i++) {
                          arrayToObj[toOrder[i].id] = toOrder[i];
                        }

                        sections[sectionID]['questions'] = arrayToObj;
                      }

                      if (sectionNum == numSections) {
                        // section object complete

                        let requiredQuestionsList = {};
                        // Get pure list of all quesions
                        Object.keys(sections).map((sectionKey, v) => {
                          Object.assign(requiredQuestionsList, sections[sectionKey]['questions']);
                        });

                        // Remove all non-required questions
                        Object.keys(requiredQuestionsList).map((i, v) => {
                          if (requiredQuestionsList[i]['required'] == 'false') {
                            delete requiredQuestionsList[i];
                          }
                        });

                        //check to see if there is a userID for them
                        //this is a .where() search so it will always return a value, check for .docs.length
                        db.checkForReviewerOnly(this.state.accountID, this.state.reviewerDetails.email)
                          .then((res) => {
                            let userID = false;
                            //if there is a userID for the person then set it, if not then leave it at false
                            if (res.docs.length > 0) {
                              res.docs.forEach((doc, i) => {
                                userID = doc.id;
                              });
                            }
                            
                            this.setState({
                              userID: userID,
                              questions: sections,
                              requiredQuestionsList: requiredQuestionsList,
                              questionnaireType: questionnaireType,
                              questionsLoaded: true,
                            });
                          })
                          .catch((err) => {
                            console.log(err);
                            this.setState({
                              questions: sections,
                              requiredQuestionsList: requiredQuestionsList,
                              questionnaireType: questionnaireType,
                              questionsLoaded: true,
                            });
                          });
                      }
                    });
                  });
                }
              });
            });

            db.doGetQuestionnaireSections(params.accountID, reviewerDetails.questionnaireID).then((snapshot) => {
              let sections = {};
              if (!snapshot.empty) {
                let sectionNum = 0;
                let numSections = snapshot.docs.length;
                snapshot.docs.map((doc) => {
                  let sectionID = doc.id;
                  sections[sectionID] = doc.data();
                  sectionNum++;

                  let scaleLabels = {};
                  let reverseOrderedLabelIDs = []

                  db.doGetQuestionnaireLabels(params.accountID, reviewerDetails.questionnaireID).then((res) => {
                    if (res.docs.length > 0) {
                      res.docs.map((doc) => {
                        scaleLabels[doc.id] = doc.data();
                        reverseOrderedLabelIDs.push(doc.id);
                      });
                    } else {
                      // adds legacy default labels
                      scaleLabels = {
                        rating0: { score: 0, text: 'Unable to comment', frequency: '', sortOrder: 0 },
                        rating1: { score: 1, text: 'Never', frequency: '0% of the time', sortOrder: 1 },
                        rating2: { score: 2, text: 'Rarely', frequency: '1% - 35% of the time', sortOrder: 2 },
                        rating3: { score: 3, text: 'Some of the time', frequency: '36% - 59% of the time', sortOrder: 3 },
                        rating4: { score: 4, text: 'Quite often', frequency: '60% - 89% of the time', sortOrder: 4 },
                        rating5: { score: 5, text: 'Frequently if not always', frequency: 'over 90% of the time', sortOrder: 5 },
                      };
                      reverseOrderedLabelIDs = Object.keys(scaleLabels)
                    }

                    reverseOrderedLabelIDs
                      .sort((a,b) => {
                        return scaleLabels[a].sortOrder - scaleLabels[b].sortOrder
                      })
                      .reverse()

                    db.doGetReviewerAnswersOLD(params.accountID, params.reviewerID, sectionID).onSnapshot((snapshot) => {
                      if (snapshot.data()) {
                        sections[sectionID]['answers'] = snapshot.data();

                        if (sectionNum == numSections) {
                          sleep( 3000 ).then(() => { // add delay to ensure answers correctly loaded into state
                            this.setState({
                              scaleLabels,
                              reverseOrderedLabelIDs,
                              answers: sections,
                              answersLoaded: true,
                            });
                          })
                        }
                      } else {
                        db.doGetReviewerAnswers(params.accountID, params.reviewerID, sectionID).onSnapshot((snapshot) => {
                          if (snapshot.data()) {
                            sections[sectionID]['answers'] = snapshot.data();
                          }

                          if (sectionNum == numSections) {
                            sleep( 3000 ).then(() => { // add delay to ensure answers correctly loaded into state
                              this.setState({
                                scaleLabels,
                                reverseOrderedLabelIDs,
                                answers: sections,
                                answersLoaded: true,
                              });
                            })
                            
                          }
                        });
                      }
                    });
                  });
                });
              }
            });
          });
        });
      }
    });
    //
    // db.doGetQuestionnaireType(params.accountID, reviewerDetails.questionnaireID).then((snapshot) => {
    //   let questionnaireType = snapshot.data()['type']
    //
    //
    //   db.doGetQuestionnaireSections(params.accountID, reviewerDetails.questionnaireID).then((snapshot) => {
    //     // console.log(snapshot);
    //     if(!snapshot.empty) {
    //
    //       let sections = {}
    //       let sectionNum = 0
    //       let numSections = snapshot.docs.length
    //       snapshot.docs.map(doc => {
    //         let sectionID = doc.id
    //         sections[sectionID] = doc.data()
    //         sectionNum++
    //
    //         db.doGetQuestions(params.accountID, reviewerDetails.questionnaireID, sectionID).then((snapshot) => {
    //           let questions = {}
    //           snapshot.docs.map(doc => {
    //             questions[doc.id] = doc.data()
    //           })
    //           sections[sectionID]['questions'] = questions
    //
    //           if(sectionNum == numSections) {
    //             // section object complete
    //
    //             let requiredQuestionsList = {}
    //             // Get pure list of all quesions
    //             Object
    //               .keys(sections)
    //               .map((sectionKey, v) => {
    //                 Object.assign(requiredQuestionsList, sections[sectionKey]['questions'])
    //               })
    //
    //             // Remove all non-required questions
    //             Object
    //               .keys(requiredQuestionsList)
    //               .map((i, v) => {
    //                 if(requiredQuestionsList[i]['required'] == "false") {
    //                   delete requiredQuestionsList[i]
    //                 }
    //               })
    //
    //             this.setState({
    //               questions: sections,
    //               requiredQuestionsList: requiredQuestionsList,
    //               questionnaireType: questionnaireType,
    //               questionsLoaded: true,
    //             })
    //           }
    //
    //
    //           // db.doGetReviewerAnswers(params.accountID, params.reviewerID).on('value', data => {
    //           //   this.setState({
    //           //     answers: data.val()['sections'],
    //           //     completionPercentage: data.val()['completionPercentage'],
    //           //     answersLoaded: true
    //           //   })
    //           // })
    //
    //         })
    //       })
    //
    //     })
    //
    //   })

    // Get Questions
    // db.doGetQuestions(params.accountID, reviewerDetails.questionnaireID).on('value', data => {
    //   console.log(data.val());
    //   if(data.val()) {
    //
    //     let requiredQuestionsList = {}
    //
    //     // Get pure list of all quesions
    //     Object
    //       .keys(data.val()['sections'])
    //       .map((section, v) => {
    //         Object.assign(requiredQuestionsList, data.val()['sections'][section]['questions'])
    //       })
    //
    //     // Remove all non-required questions
    //     Object
    //       .keys(requiredQuestionsList)
    //       .map((i, v) => {
    //         if(requiredQuestionsList[i]['required'] == "false") {
    //           delete requiredQuestionsList[i]
    //         }
    //       })
    //
    //     this.setState({
    //       questions: data.val(),
    //       requiredQuestionsList: requiredQuestionsList,
    //       questionsLoaded: true,
    //     })
    //
    //     // this.updateCompletionPercentage()
    //   }
    //
    //   // Get Answers
    //   db.doGetReviewerAnswers(params.accountID, params.reviewerID).on('value', data => {
    //     this.setState({
    //       answers: data.val()['sections'],
    //       completionPercentage: data.val()['completionPercentage'],
    //       answersLoaded: true
    //     })
    //   })
    // })

    // })

    // }

    // })
  }

  questionUpdated(sectionID, questionID, answer) {
    // console.log(this.state.accountID, this.state.reviewerID, sectionID, questionID, answer);

    db.doUpdateReviewerAnswer(this.state.accountID, this.state.reviewerID, sectionID, questionID, answer)
      .then(() => {
        // On complete, calculate completion percentage
        this.updateCompletionPercentage();
      })
      .catch((error) => {});

    // Update answer
    // const updateAnswerRef = db.doUpdateReviewerAnswer(this.state.accountID, this.state.reviewerID, sectionID, questionID, answer)
    //
    // updateAnswerRef.then(() => {
    //   console.log('asdfasdfas');
    //   // On complete, calculate completion percentage
    //   this.updateCompletionPercentage()
    // }).catch((error)=> {
    //   console.log("Data could not be saved." + error);
    // });
  }

  updateCompletionPercentage() {
    let answersList = {};
    let requiredQuestionsComplete = 0;
    const {answers, requiredQuestionsList, accountID, reviewerDetails, reviewerID} = this.state
    let numberOfRequiredQuestions = Object.keys(requiredQuestionsList).length;

    Object.keys(answers).map((section, v) => {
      Object.assign(answersList, answers[section]['answers']);
    });

    Object.keys(requiredQuestionsList).map((key) => {
      if (key in answersList) {
        if (requiredQuestionsList[key].answerType === 'Words' && answersList[key].length === 0) {
        } else {
          requiredQuestionsComplete++;
        }
      }
    });

    let completionPercentage = Math.floor((requiredQuestionsComplete * 100) / numberOfRequiredQuestions);
    this.setState({
      completionPercentage: completionPercentage,
    });
    // Update completion % on DB
    db.doUpdateReviewerCompletionPercentage(accountID, reviewerID, completionPercentage)
      .then((data) => {
        // On complete update button
        if (completionPercentage === 100) {
          db.setReviewToCompleted(
            accountID,
            reviewerDetails.current360ID,
            reviewerDetails.groupID,
            reviewerDetails.userID,
            reviewerID
          );
          this.setState({
            completionPercentage: completionPercentage,
          });
        } else if (this.state.questionnaireType === 'Team Assessment') {
          db.updateTeamAssessmentCompletedStatus(accountID, reviewerDetails.current360ID, reviewerDetails.groupID, reviewerDetails.userID, reviewerID,completionPercentage)
        }
      })
      .catch((error) => {
        console.log('Data could not be saved.' + error);
      });
  }

  render() {
    const {
      match: { params },
    } = this.props;

    const questionnaireType = this.state.questionnaireType;

    if (this.state.questionsLoaded && this.state.answersLoaded) {
      return (
        <main>
          <SiteHeaderReviewer reviewerDetails={this.state.reviewerDetails} completionPercentage={this.state.completionPercentage} />
          {this.state.reviewerDetails.hasSelfReview && this.state.reviewerDetails.currentReviewerID ? (
            <SiteNav userID={this.state.reviewerDetails.currentReviewerID} accountID={this.state.accountID} />
          ) : this.state.userID !== false ? (
            <SiteNav userID={this.state.userID} accountID={this.state.accountID} />
          ) : (
            <SiteNav userID={this.state.reviewerID} accountID={this.state.accountID} />
          )}

          <section className='page-wrapper'>
            <div className='container container--slim'>
              <h1 className='mb0'>
                You are currently completing{' '}
                {projectTypeText(
                  questionnaireType,
                  ` ${this.state.userDetails.forename} ${this.state.userDetails.surname}'s 360`,
                  ` the Team Assessment for ${this.state.userDetails.name}`
                )}
              </h1>

              <div className='w-100 mt3'>
                <h2 className='near-black' style={{fontSize: '.8125rem', lineHeight: '1.8', marginBottom: '1.4em'}}>
                  <strong>When completing feedback for {projectTypeText(questionnaireType, 'someone else', 'your team')}:</strong>
                </h2>
                <p>
                  &bull; When rating {projectTypeText(questionnaireType, "someone else's", "the team's collective")} behaviour or effectiveness, try to be as
                  objective as possible{projectTypeText(questionnaireType, '.', ', without being unduly modest.')}
                  <br />
                  &bull; {projectTypeText(questionnaireType, 'G', 'Where possible, g')}ive as many concrete examples as you can to support your ratings
                  {projectTypeText(questionnaireType, '; particulalrly if you chose to provide a low rating', '')}.
                  <br />
                  {questionnaireType !== 'Team Assessment' ? (
                    <>
                      &bull; Try to be constructive when providing feedback to others. Remember, you are trying to help someone to learn and develop.
                      <br />
                      &bull; Be respectful – remember you are providing feedback on specific behaviours or effectiveness, not on the individual’s personality
                      <br />
                      &bull; Consider the words you use - opinions can often sound like clear cut facts, if not worded appropriately.
                      <br />
                      &bull; Allow yourself enough time to collate your evidence, reflect and complete the feedback.
                      <br />
                      &bull; If you leave the platform at any stage, the feeback you have provided to that point, will be automatically saved.
                    </>
                  ) : (
                    <>&bull; Consider behaviour or effectiveness over an extended period, not just the most recent events that come quickly to mind.</>
                  )}
                </p>
              </div>

              {Object.keys(this.state.questions).map((key, i) => {
                // Check question exists
                if (this.state.questions[key].questions) {
                  return (
                    <Questionnaire
                      key={key}
                      index={key}
                      title={this.state.questions[key].title}
                      description={this.state.questions[key].description}
                      questions={this.state.questions[key].questions}
                      answers={this.state.answers[key] ? this.state.answers[key].answers : null}
                      questionUpdated={this.questionUpdated}
                      questionnaireType={this.state.questionnaireType}
                      showSavedModal={this.showSavedModal}
                      goalActive={this.state.currentGoalID == i + 1 || this.state.currentGoalID == null ? true : false}
                      scaleLabels={this.state.scaleLabels}
                      reverseOrderedLabelIDs={this.state.reverseOrderedLabelIDs}
                    />
                  );
                } else {
                  return false;
                }
              })}
            </div>
          </section>
          <SiteFooterReviewer
            completionPercentage={this.state.completionPercentage}
            groupSettings={{
              accountID: this.state.accountID,
              current360ID: this.props.match.params.current360ID,
              currentGroupID: this.state.reviewerDetails.groupID,
            }}
          />
        </main>
      );
    } else {
      if (this.state.userNotFound) {
        return (
          <div className='vh-100 vw-100 flex items-center justify-center'>
            <p>Review not found</p>
          </div>
        );
      } else {
        return (
          <div className="page-loading-state">
            <img src={ loadingGif } />
          </div>
        );
      }
    }
  }
}

// answers={this.state.answers.Sections[key].Answers}
// export default Review
export default withBranding(authCondition)(Review);
