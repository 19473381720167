import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import axios from 'axios';
// Routes
import pageRoutes from './constants/routes';
import NoMatch from './components/no-match';
import Forward from './components/forward';
import withAuthentication from './components/withauthentication'; // withAuthentication higher level component - abstract the auth logic away

class App extends Component {
  componentDidMount() {
    axios.defaults.baseURL = process.env.REACT_APP_AXIOS_BASE_URL;
  }

  render() {
    return (
      <Router>
        <Switch>
          {pageRoutes.map(({ path, component, exact }, key) => (
            <Route exact={exact} path={path} key={key} component={component} />
          ))}
          <Route exact path="/" component={Forward} />
          <Route component={NoMatch} />
        </Switch>
      </Router>
    );
  }
}

export default withAuthentication(App);
