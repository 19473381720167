export const lightBoxStyles = {
  overlay: {
    zIndex                : '9999',
    backgroundColor       : 'rgba(0, 0, 0, 0.75)',
  },
  content : {
    top                   : '50%',
    left                  : '50%',
    right                 : 'auto',
    bottom                : 'auto',
    marginRight           : '-50%',
    width                 : '500px',
    maxHeight             : '85vh',
    padding               : '40px',
    maxWidth              : '85vw',
    border                : 'none',
    backgroundColor       : 'rgb(255, 255, 255)',
    transform             : 'translate(-50%, -50%)'
  }
}
