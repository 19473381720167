import React, { Component } from 'react';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

class Question extends Component {
  constructor(props) {
    super(props);
    this.state = {
      answer: this.props.answer,
      selectedWords: [],
    };
    this.updateSelectedWords = this.updateSelectedWords.bind(this);
  }

  componentDidMount() {
    // if(this.props.question.answerType === 'FreeText') {
    //   console.log("!!!!")
    //   console.log(this.props.answer)
    // }
    if (this.props.answer === null && this.props.question.required === 'false' && this.props.question.answerType === 'FreeText') {
      this.props.questionUpdated(this.props.section, this.props.index.replace(this.props.section, ''), '');
    }
    if (this.props.question.answerType === 'Words' && this.props.answer) {
      this.setState({ selectedWords: this.props.answer });
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps !== this.props) {
      this.setState({
        answer: this.props.answer,
      });
    }
  }

  handleChange = (e) => {
    this.setState({ answer: e.target.value });
  };

  updateSelectedWords = (e, QuestionID, word) => {
    let selectedWords = [...this.state.selectedWords];
    if (word.checked) {
      selectedWords.push(word.value);
    } else {
      const uncheckedPosition = selectedWords.indexOf(word.value);
      selectedWords.splice(uncheckedPosition, 1);
    }
    this.setState({ selectedWords });
    this.props.questionUpdated(this.props.section, QuestionID, selectedWords);
  };

  radioButton(radioNum, radioLabel, answer) {
    let index;
    if (this.props.legacy) {
      index = this.props.index.replace(this.props.section, '');
    } else {
      index = this.props.index.replace(this.props.section, '');
    }
    return (
      <label className='relative w-100-l flex items-center justify-center-l mv2 bl-l b--light-silver'>
        <input
          disabled={!this.props.questionActive}
          required={this.props.question.required ? 'required' : ''}
          checked={this.props.answer == radioNum ? 'checked' : ''}
          onChange={(e) => this.props.questionUpdated(this.props.section, index, e.target.value)}
          value={radioNum}
          name={this.props.index}
          type='radio'
          className='absolute z-5 w-100 h-100 o-0 pointerinput-reset pointer radiobutton'
        />
        <span className='relative dib w1 h1 bg-light-gray overflow-hidden br-100 v-mid bg-animate bg-center radiobutton-wrapper'>
          <div className='absolute top-0 left-0 w1 h1 ba bw2 b--transparent br-100' />
        </span>
        <div className='dib dn-l ml2 lh-solid'>
          {radioNum} {radioLabel}
        </div>
      </label>
    );
  }

  render() {
    const {scaleLabels, reverseOrderedLabelIDs, answer} = this.props
    if (this.props.question.answerType === 'MultiChoice') {
      return (
        <div className='flex-l justify-between items-center bt b--light-silver pv3'>
          <div className='w-40-l'>
            <h3 className='reset-mar' style={{fontSize: '.8125rem', lineHeight: '1.8'}}>
              <strong>{this.props.question.questionTitle}</strong>
            </h3>
          </div>
          <div className='w-60-l flex-l justify-around items-center ml2'>
            {reverseOrderedLabelIDs.map((labelID, i) => {
              const {score, text, frequency, sortOrder} = scaleLabels[labelID]
              const labelContent = `${text}${sortOrder > 0 ? ` (${frequency})` : ''}`;
              return this.radioButton(score, labelContent, answer);
            })}
          </div>
        </div>
      );
    }
    if (this.props.question.answerType === 'FreeText') {
      return (
        <div className='bt b--light-silver pv3'>
          <label htmlFor={`text-answer-${this.props.section}${this.props.index}`} style={{margin: '0 0 1.4em'}}>
            <strong>{this.props.question.questionTitle}</strong>
          </label>
          <textarea
            disabled={!this.props.questionActive}
            required={this.props.question.required ? 'required' : ''}
            onBlur={(e) => this.props.questionUpdated(this.props.section, this.props.index.replace(this.props.section, ''), e.target.value)}
            onChange={(e) => this.handleChange(e)}
            defaultValue={this.props.answer}
            value={this.state.answer}
            rows='6'
            placeholder={this.props.questionnaireType === 'Goal Tracker' ? '...' : 'Type your response here'}
            id={`text-answer-${this.props.section}${this.props.index}`}
          />
        </div>
      );
    }
    if (this.props.question.answerType === 'Words') {
      const selectedWords = this.state.selectedWords ? this.state.selectedWords : [];
      return (
        <div className='flex-l justify-between items-center bt b--light-silver pv3'>
          <div className='w-40-l'>
            <h3 className='reset-mar' style={{fontSize: '.8125rem', lineHeight: '1.8'}}>
              <strong>{this.props.question.questionTitle}</strong>
            </h3>
          </div>
          <div className='w-60-l flex-l justify-around items-center ml2'>
            {this.props.question.words.map((word, i) => {
              const wordID = `word${i}`;
              return (
                <div key={wordID} className='relative w-100-l flex items-center justify-center-l mv2 bl-l b--light-silver'>
                  <div className='flex flex-column items-center'>
                    <label className='ma0' for={wordID}>
                      {word}
                    </label>
                    <input
                      type='checkbox'
                      id={wordID}
                      name={wordID}
                      value={word}
                      checked={selectedWords.includes(word)}
                      onChange={(e) => this.updateSelectedWords(e, this.props.question.id, e.target)}
                    />
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      );
    }
    if (this.props.question.answerType === 'Date') {
      return (
        <div className='bt b--light-silver'>
          <label htmlFor={`date-select-${this.props.section}${this.props.index}`} className='pt3' style={{margin: '0 0 1.4em'}}>
            <strong>{this.props.question.questionTitle}</strong>
          </label>
          <DatePicker
            className='mt2 mb5 pa2 bg-light-gray input-reset bn outline-0 w-100'
            dateFormat='do MMM, yyyy'
            placeholderText='Select a date'
            selected={this.props.answer ? this.props.answer.toDate() : null}
            onChange={(date) => this.props.questionUpdated(this.props.section, this.props.index.replace(this.props.section, ''), date)}
            disabled={!this.props.questionActive}
            id={`date-select-${this.props.section}${this.props.index}`}
            aria-describedby='date-selection'
          />
        </div>
      );
    }
  }
}

export default Question;
