import { db } from './firebase';

export const doGetQuestionnaireSections = (accountID, questionnaireID) => {
  return new Promise(function(resolve, reject) {
    db.collection(`accounts`)
      .doc(accountID)
      .collection(`questionnaires`)
      .doc(questionnaireID)
      .collection('sections')
      .orderBy(`created`)
      .get()
      .then((snapshot) => {
        if (snapshot.empty) {
          db.collection(`accounts`)
            .doc(accountID)
            .collection(`questionnaires`)
            .doc(questionnaireID)
            .collection('sections')
            .get()
            .then((snapshot) => {
              resolve(snapshot);
            });
        } else {
          if (snapshot.docs.map((doc) => doc.data().sortOrder).includes(undefined)) {
            resolve(snapshot);
          } else {
            db.collection(`accounts`)
              .doc(accountID)
              .collection(`questionnaires`)
              .doc(questionnaireID)
              .collection('sections')
              .orderBy(`sortOrder`)
              .get()
              .then((snapshot) => {
                resolve(snapshot);
              });
          }
        }
      });
  });
};
// db.collection(`accounts`).doc(accountID).collection(`questionnaires`).doc(questionnaireID).collection(`sections`).get()

export const doGetQuestions = (accountID, questionnaireID, sectionID) =>
  db
    .collection(`accounts`)
    .doc(accountID)
    .collection(`questionnaires`)
    .doc(questionnaireID)
    .collection(`sections`)
    .doc(sectionID)
    .collection(`questions`)
    .get();

export const doGetQuestionnaireType = (accountID, questionnaireID) =>
  db
    .collection(`accounts`)
    .doc(accountID)
    .collection(`questionnaires`)
    .doc(questionnaireID)
    .get();

export const doGetAnswers = (accountID, userID, current360ID, groupID, questionnaireID, reviewType, sectionID) =>
  db
    .collection(`accounts`)
    .doc(accountID)
    .collection(`users`)
    .doc(userID)
    .collection(`360s`)
    .doc(current360ID)
    .collection(`group`)
    .doc(groupID)
    .collection(`questionnaires`)
    .doc(questionnaireID)
    .collection(reviewType)
    .doc(`sections`)
    .collection(sectionID)
    .doc(`answers`);

// db.ref(`accounts/${accountID}/users/${userID}/360s/${current360ID}/group/${groupID}/questionnaires/${questionnaireID}/${reviewType}/`)

export const doGetReviewerAnswers = (accountID, reviewerID, sectionID) =>
  db
    .collection(`accounts`)
    .doc(accountID)
    .collection(`reviewers`)
    .doc(reviewerID)
    .collection(`review`)
    .doc(`sections`)
    .collection(sectionID)
    .doc(`answers`);

export const doGetReviewerAnswersOLD = (accountID, reviewerID, sectionID) =>
  db
    .collection(`accounts`)
    .doc(accountID)
    .collection(`reviewers`)
    .doc(reviewerID)
    .collection(`review`)
    .doc(`section`)
    .collection(sectionID)
    .doc(`answers`);

// export const doGetReviewerAnswers = (accountID, reviewerID) =>
//   db.ref(`accounts/${accountID}/reviewers/${reviewerID}/review/`)

export const doGetClientLogo = (accountID, current360ID) => {
  return new Promise(function(resolve, reject) {
    db.ref(`accounts/${accountID}/360s/${current360ID}/brand/logo`).once('value', (snapshot) => {
      if (snapshot.val()) {
        resolve(snapshot.val());
      } else {
        db.ref(`accounts/${accountID}/settings/logo`).once('value', (snapshot) => {
          if (snapshot.val()) {
            resolve(snapshot.val());
          } else {
            reject('no logo found');
          }
        });
      }
    });
  });
};

export const doGetGenericAccountBranding = (accountID) =>
  db
    .collection(`accounts`)
    .doc(accountID)
    .get();

export const doGetAccountBranding = (accountID, current360ID) => db.ref(`accounts/${accountID}/360s/${current360ID}/settings/`).once('value');

export const doGetAccountBrandingAndRelationships = (accountID, current360ID) => {
  let brandingAndRelationships = {};
  return new Promise(function(resolve, reject) {
    db.collection(`accounts`)
      .doc(accountID)
      .collection(`360s`)
      .doc(current360ID)
      .get()
      .then((snapshot) => {
        // db.ref(`accounts/${accountID}/360s/${current360ID}/settings/`).once("value", (snapshot) => {

        if (snapshot.exists) {
          let accountBrandingDetails = snapshot.data()['settings'];
          brandingAndRelationships = accountBrandingDetails;
          db.collection(`accounts`)
            .doc(accountID)
            .collection('360s')
            .doc(current360ID)
            .collection(`relationships`)
            .get()
            .then((snapshot) => {
              let relationships = {};
              snapshot.docs.map((doc, i) => {
                relationships[doc.id] = doc.data();
              });

              brandingAndRelationships['relationships'] = relationships;
              resolve(brandingAndRelationships);
            });
        } else {
          reject();
        }
      });
  });
};

export const doUpdateAnswer = (accountID, userID, current360ID, groupID, questionnaireID, reviewType, sectionID, questionID, answer) => {
  return db
    .collection(`accounts`)
    .doc(accountID)
    .collection(`users`)
    .doc(userID)
    .collection(`360s`)
    .doc(current360ID)
    .collection(`group`)
    .doc(groupID)
    .collection(`questionnaires`)
    .doc(questionnaireID)
    .collection(reviewType)
    .doc(`sections`)
    .collection(sectionID)
    .doc(`answers`)
    .set(
      {
        [questionID]: answer,
      },
      { merge: true }
    );
};

export const doUpdateSectionStatus = (accountID, userID, current360ID, groupID, questionnaireID, reviewType, sectionID) => {
  return db
    .collection(`accounts`)
    .doc(accountID)
    .collection(`users`)
    .doc(userID)
    .collection(`360s`)
    .doc(current360ID)
    .collection(`group`)
    .doc(groupID)
    .collection(`questionnaires`)
    .doc(questionnaireID)
    .collection(reviewType)
    .doc(`sections`)
    .collection(sectionID)
    .doc(`status`)
    .set(
      {
        goalComplete: true,
      },
      { merge: true }
    );
};

export const doUpdateReviewerAnswer = (accountID, reviewerID, sectionID, questionID, answer) => {
  return db
    .collection(`accounts`)
    .doc(accountID)
    .collection(`reviewers`)
    .doc(reviewerID)
    .collection(`review`)
    .doc(`sections`)
    .collection(sectionID)
    .doc(`answers`)
    .set(
      {
        [questionID]: answer,
      },
      { merge: true }
    );
};

//   return db.collection(`accounts`).doc(accountID).
//     collection(`reviewers`).doc(reviewerID).
//       collection(`review`).doc(sections).
//         collection(`group`).doc(groupID).
//           collection(`questionnaires`).doc(questionnaireID).
//             collection(reviewType).doc(`sections`).
//               collection(sectionID).doc(`answers`).set({
//                 [questionID]: answer
//               }, { merge: true })
//
//   let newAnswer = {}
//   newAnswer[questionID] = answer
//
//   return db.ref(`accounts/${accountID}/reviewers/${reviewerID}/review/sections/${sectionID}/answers/`).update(
//     newAnswer
//   )
// }

export const doUpdateCompletionPercentage = (accountID, userID, current360ID, groupID, questionnaireID, reviewType, completionPercentage) => {
  return db
    .collection(`accounts`)
    .doc(accountID)
    .collection(`users`)
    .doc(userID)
    .collection(`360s`)
    .doc(current360ID)
    .collection(`group`)
    .doc(groupID)
    .collection(`questionnaires`)
    .doc(questionnaireID)
    .collection(reviewType)
    .doc(`status`)
    .set(
      {
        completionPercentage: completionPercentage,
        completed: completionPercentage === 100 ? true : false,
      },
      { merge: true }
    );
};

export const getSelfReviewCompletionPercentage = (accountID, userID, current360ID, groupID, questionnaireID, reviewType) => {
  return new Promise(function(resolve, reject) {
    db.collection(`accounts`)
      .doc(accountID)
      .collection(`users`)
      .doc(userID)
      .collection(`360s`)
      .doc(current360ID)
      .collection(`group`)
      .doc(groupID)
      .collection(`questionnaires`)
      .doc(questionnaireID)
      .collection(reviewType)
      .doc(`status`)
      .get()
      .then((snapshot) => {
        if (snapshot.data() && snapshot.data()['completionPercentage']) {
          resolve(snapshot.data()['completionPercentage']);
        } else {
          resolve(0);
        }
      });
  });
};

export const getGroupDeadlineDate = (accountID, current360ID, groupID) => {
  return new Promise(function(resolve, reject) {
    db.collection(`accounts`)
      .doc(accountID)
      .collection(`360s`)
      .doc(current360ID)
      .collection(`groups`)
      .doc(groupID)
      .get()
      .then((snapshot) => {
        if (snapshot.data()) {
          resolve(snapshot.data()['deadlineDate']);
        } else {
          resolve(false);
        }
      });
  });
};

export const doUpdateReviewerCompletionPercentage = (accountID, reviewerID, completionPercentage) => {
  return db
    .collection(`accounts`)
    .doc(accountID)
    .collection(`reviewers`)
    .doc(reviewerID)
    .collection(`review`)
    .doc(`status`)
    .set(
      {
        completionPercentage: completionPercentage,
        completed: completionPercentage === 100 ? true : false,
      },
      { merge: true }
    );
};

export const setReviewToCompleted = (accountID, current360Id, groupID, userID, reviewerID) => {
  let newCompletionStatus = {};
  newCompletionStatus['completed'] = true;

  if (reviewerID) {
    return db
      .collection(`accounts`)
      .doc(accountID)
      .collection(`360s`)
      .doc(current360Id)
      .collection(`groups`)
      .doc(groupID)
      .collection(`users`)
      .doc(userID)
      .collection(`reviewers`)
      .doc(reviewerID)
      .set(
        {
          completed: true,
        },
        { merge: true }
      );

    // return db.ref(`accounts/${accountID}/360s/${current360Id}/groups/${groupID}/users/${userID}/reviewers/${reviewerID}`).update(
    //   newCompletionStatus
    // )
  } else {
    return db
      .collection(`accounts`)
      .doc(accountID)
      .collection(`360s`)
      .doc(current360Id)
      .collection(`groups`)
      .doc(groupID)
      .collection(`users`)
      .doc(userID)
      .set(
        {
          completed: true,
        },
        { merge: true }
      );
    // return db.ref(`accounts/${accountID}/360s/${current360Id}/groups/${groupID}/users/${userID}/`).update(
    //   newCompletionStatus
    // )
  }
};

export const updateTeamAssessmentCompletedStatus = (accountID, current360Id, groupID, userID, reviewerID, completionPercentage) => {
  if (reviewerID) {
    return db
      .collection(`accounts`)
      .doc(accountID)
      .collection(`360s`)
      .doc(current360Id)
      .collection(`groups`)
      .doc(groupID)
      .collection(`users`)
      .doc(userID)
      .collection(`reviewers`)
      .doc(reviewerID)
      .set(
        {
          completionPercentage,
          completed: completionPercentage === 100,
        },
        { merge: true }
      );
  }
};

export const doGetSelfReviewInfo = (accountID, userID, current360ID, groupID, questionnaireID) =>
  db
    .collection(`accounts`)
    .doc(accountID)
    .collection(`users`)
    .doc(userID)
    .collection(`360s`)
    .doc(current360ID)
    .collection(`group`)
    .doc(groupID)
    .collection(`questionnaires`)
    .doc(questionnaireID)
    .collection(`self-review`)
    .doc(`status`)
    .get();

// db.ref(`accounts/${accountID}/users/${userID}/360s/${current360ID}/group/${groupID}/questionnaires/${questionnaireID}/self-review/`).once('value')

export const doGetCurrentUser = (accountID, userID) =>
  db
    .collection(`accounts`)
    .doc(accountID)
    .collection(`users`)
    .doc(userID)
    .get();

export const doGetCurrentUser360s = (accountID, userID) => {
  return db
    .collection(`accounts`)
    .doc(accountID)
    .collection(`users`)
    .doc(userID)
    .collection(`360s`);
};

export const doGetCurrentReviewerRef = (accountID, reviewerID) =>
  db
    .collection(`accounts`)
    .doc(accountID)
    .collection(`reviewers`)
    .doc(reviewerID)
    .get();

export const doGetCurrentReviewStatus = (accountID, reviewerID) =>
  db
    .collection(`accounts`)
    .doc(accountID)
    .collection(`reviewers`)
    .doc(reviewerID)
    .collection(`review`)
    .doc(`status`)
    .get();

export const getCurrentGroupDetails = (accountID, current360ID, groupID) =>
  db
    .collection(`accounts`)
    .doc(accountID)
    .collection(`360s`)
    .doc(current360ID)
    .collection(`groups`)
    .doc(groupID)
    .get();

// db.ref(`accounts/${accountID}/reviewers/${reviewerID}`)

export const getCurrentUserReviewers = (accountID, current360ID, groupID, userID, relationships) => {
  let reviewersObj = {};
  let reviewersObjSortedByRelationship = {};

  Object.keys(relationships).map((key) => {
    reviewersObjSortedByRelationship[key] = {};
  });

  return db
    .collection(`accounts`)
    .doc(accountID)
    .collection(`360s`)
    .doc(current360ID)
    .collection(`groups`)
    .doc(groupID)
    .collection(`users`)
    .doc(userID)
    .collection(`reviewers`);
  // return db.collection(`accounts`).doc(accountID).collection(`360s`).doc(current360ID).collection(`groups`).doc(groupID).collection(`users`).doc(userID).collection(`reviewers`).get()

  // return new Promise(function(resolve, reject) {
  //   db.collection(`accounts`).doc(accountID).collection(`360s`).doc(current360ID).collection(`groups`).doc(groupID).collection(`users`).doc(userID).collection(`reviewers`).get().then((snapshot) => {
  //
  //     let reviewerObj = {}
  //     let numberOfReviewers = snapshot.docs.length
  //
  //     console.log("numberOfReviewers = "+numberOfReviewers);
  //     snapshot.docs.map((doc, i) => {
  //       reviewerObj[doc.id] = doc.data()
  //     })
  //
  //     let checkComplete = (count) => {
  //       count = count + 1
  //       if(count == Object.keys(reviewerObj).length) {
  //         return true
  //       } else {
  //         return false
  //       }
  //     }
  //
  //     if(Object.keys(reviewerObj).length == 0) {
  //       resolve(reviewersObjSortedByRelationship)
  //     }
  //
  //     let batch = db.batch();
  //
  //       Object
  //         .keys(reviewerObj)
  //         .map((key, i, arr) => {
  //           let reviewerID = reviewerObj[key]['reviewerID']
  //
  //           db.collection(`accounts`).doc(accountID).collection(`reviewers`).doc(reviewerID).onSnapshot((snapshot) => {
  //             if(snapshot.data()) {
  //               reviewersObj[key] = snapshot.data()
  //               reviewersObjSortedByRelationship[ reviewersObj[key]["relationship"] ][key] = reviewersObj[key]
  //               if(checkComplete(i)) {
  //                 resolve(reviewersObjSortedByRelationship)
  //               }
  //             }
  //
  //           })
  //
  //         })
  //
  //     })
  //
  //
  //
  // })
};

export const getUserIdByEmail = (accountID, email) =>
  db
    .ref(`accounts/${accountID}/users`)
    .orderByChild(`email`)
    .equalTo(email)
    .once('value');

export const getAllUserReviewerRequests = (accountID, currentReviewerEmail) => {
  return db
    .collection(`accounts`)
    .doc(accountID)
    .collection(`reviewers`)
    .where('email', '==', currentReviewerEmail.replace(/\s+/g, ''))
    .get();
};

// doc(currentReviewerID).
// db.ref(`accounts/${accountID}/reviewers`).orderByChild(`currentReviewerID`).equalTo(currentReviewerID).once('value')

export const get360Info = (accountID, current360ID) =>
  db
    .collection(`accounts`)
    .doc(accountID)
    .collection(`360s`)
    .doc(current360ID)
    .get();

export const updateReviewerRecievedInfo = (accountID, reviewerID, current360ID, groupID, userID) => {
  db.collection(`accounts`)
    .doc(accountID)
    .collection(`reviewers`)
    .doc(reviewerID)
    .set(
      {
        recievedDate: Date.now(),
        emailRecieved: true,
        notificationCount: 1,
      },
      { merge: true }
    );

  db.collection(`accounts`)
    .doc(accountID)
    .collection(`360s`)
    .doc(current360ID)
    .collection(`groups`)
    .doc(groupID)
    .collection(`users`)
    .doc(userID)
    .collection(`reviewers`)
    .doc(reviewerID)
    .set(
      {
        emailRecieved: true,
      },
      { merge: true }
    );
};

export const getReviewerReviewStatus = (accountID, reviewerID) =>
  db
    .collection(`accounts`)
    .doc(accountID)
    .collection(`reviewers`)
    .doc(reviewerID)
    .collection(`review`)
    .doc(`status`)
    .get();

export const createNewReviewer = (accountID, revieweeName, name, emailAddress, relationship, current360, groupID, currentQuestionnaireID, userID) => {
  return new Promise(function(resolve, reject) {
    let hasSelfReview = false;
    let currentReviewerID = null;

    // db.ref(`accounts/${accountID}/users`).orderByChild(`email`).equalTo(emailAddress).once("value",snapshot => {
    db.collection(`accounts`)
      .doc(accountID)
      .collection(`users`)
      .where('email', '==', emailAddress)
      .get()
      .then((snapshot) => {
        if (!snapshot.empty) {
          hasSelfReview = true;
          snapshot.docs.map((doc, i) => {
            currentReviewerID = doc.id;
          });
        }

        let reviewerObj = {
          emailRecieved: false,
          notificationCount: 0,
          name: name,
          email: emailAddress,
          relationship: relationship,
          current360ID: current360,
          questionnaireID: currentQuestionnaireID,
          groupID: groupID,
          userID: userID,
          hasSelfReview: hasSelfReview,
          currentReviewerID: currentReviewerID,
          currentRevieweeName: revieweeName,
        };
        // db.ref(`accounts/${accountID}/reviewers`).push(

        db.collection(`accounts`)
          .doc(accountID)
          .collection(`reviewers`)
          .add(reviewerObj)
          .then((snapshot) => {
            let reviewerKey = snapshot.id;

            db.collection(`accounts`)
              .doc(accountID)
              .collection(`reviewers`)
              .doc(reviewerKey)
              .collection(`review`)
              .doc(`status`)
              .set(
                {
                  completed: false,
                  completionPercentage: 0,
                },
                { merge: true }
              )
              .then((snapshot) => {
                let splitName = name.split(' ');
                doCreateNonAdminUser(accountID, splitName[0], splitName[1], emailAddress, false)
                  .then((res) => {
                    resolve(reviewerKey);
                  })
                  .catch((err) => {
                    console.log(err);
                    resolve(reviewerKey);
                  });
              });
          });
      });
  });
};

export const doCreateNonAdminUser = (accountID, forename, surname, email, reviewerOnly) => {
  console.log('creating non-admin user for: ' + email);
  let userData = {};

  return new Promise(function(resolve, reject) {
    if (!email) {
      reject('Email invalid');
    } else {
      // search for user in
      db.collection(`accounts`)
        .doc(accountID)
        .collection(`users`)
        .where('email', '==', email)
        .get()
        .then((snapshot) => {
          if (!snapshot.empty) {
            // User exists
            snapshot.docs.map((doc, i) => {
              //if the user is a 'reviewer only' received a request to change it
              //then change it
              if (reviewerOnly !== true) {
                doc.ref.update({
                  reviewer: false,
                });
              }
              // Ensure we only get the first result...
              if (i === 0) {
                userData[doc.id] = doc.data();
              }
            });
            resolve(userData);
          } else {
            console.log('Creating new user - ' + forename + ' ' + surname + ' - ' + email);
            console.log(accountID);
            db.collection(`accounts`)
              .doc(accountID)
              .collection(`users`)
              .add({
                forename: forename,
                surname: surname,
                email: email.replace(/\s+/g, '').toLowerCase(),
                reviewer: `${reviewerOnly}`,
              })
              .then((snapshot) => {
                db.collection(`accounts`)
                  .doc(accountID)
                  .collection(`users`)
                  .doc(snapshot.id)
                  .get()
                  .then((snapshot) => {
                    userData[snapshot.id] = snapshot.data();
                    console.log('userData');
                    console.log(userData);
                    resolve(userData);
                  });
              });

            // db.ref(`accounts/${accountID}/users`).on('child_added', snapshot => {
            //
            //   resolve(
            //     { [snapshot.key]: snapshot.val() }
            //   )
            //   db.ref(`accounts/${accountID}/users`).off('child_added');
            // })
            //
            // db.ref(`accounts/${accountID}/users`).push({
            //   forename,
            //   surname,
            //   email
            // })
          }
        });
    }
  });
};

export const doDeleteReviewerFromReviewee = (accountID, reviewerID, current360ID, groupID, userID) => {
  return db
    .collection(`accounts`)
    .doc(accountID)
    .collection(`360s`)
    .doc(current360ID)
    .collection(`groups`)
    .doc(groupID)
    .collection(`users`)
    .doc(userID)
    .collection(`reviewers`)
    .doc(reviewerID)
    .delete()
    .then((data) => {
      db.collection(`accounts`)
        .doc(accountID)
        .collection(`360s`)
        .doc(current360ID)
        .collection(`groups`)
        .doc(groupID)
        .collection(`users`)
        .doc(userID)
        .collection(`reviewers`)
        .doc(reviewerID)
        .collection(`reviewer`)
        .doc(`status`)
        .delete();
    });
};

// db.ref(`accounts/${accountID}/360s/${current360ID}/groups/${groupID}/users/${userID}/reviewers/`).child(reviewerID).remove()

export const doDeleteReviewer = (accountID, reviewerID) =>
  db
    .collection(`accounts`)
    .doc(accountID)
    .collection(`reviewers`)
    .doc(reviewerID)
    .delete();

// db.ref(`accounts/${accountID}/reviewers/`).child(reviewerID).remove()

export const assignReviewerToReviewee = (accountID, reviewerID, reviewerFullname, current360ID, groupID, userID, relationship, reviewerEmail) => {
  return db
    .collection(`accounts`)
    .doc(accountID)
    .collection(`360s`)
    .doc(current360ID)
    .collection(`groups`)
    .doc(groupID)
    .collection(`users`)
    .doc(userID)
    .collection(`reviewers`)
    .doc(reviewerID)
    .set(
      {
        reviewerID: reviewerID,
        relationship: relationship,
        completed: false,
        name: reviewerFullname,
        email: reviewerEmail,
      },
      { merge: true }
    );

  // let reviewerObj = {
  //   "reviewerID": reviewerID,
  //   "relationship": relationship,
  //   "completed": false,
  //   "name": reviewerFullname,
  // }

  // return db.ref(`accounts/${accountID}/360s/${current360ID}/groups/${groupID}/users/${userID}/reviewers/`).child(reviewerID).set(reviewerObj)

  // return db.ref(`360s/${current360ID}/groups/${groupID}/users/${userID}/reviewers/`).push(
  //   reviewerObj
  // )
};

export const doGet360QuestionniareInfo = (accountID, current360ID) =>
  db
    .collection(`accounts`)
    .doc(accountID)
    .collection(`360s`)
    .doc(current360ID)
    .get();

export const doGetCurrent360Group = (accountID, current360ID, groupID) =>
  db
    .collection(`accounts`)
    .doc(accountID)
    .collection('360s')
    .doc(current360ID)
    .collection(`groups`)
    .doc(groupID)
    .get();

export const checkForReviewerOnly = (accountID, emailAddress) => {
  return db
    .collection(`accounts`)
    .doc(accountID)
    .collection(`users`)
    .where('email', '==', emailAddress)
    .get();
};

export const setGroupReportUpdated = (accountID, current360ID, currentGroupID, reportType) => {
  return db
    .collection(`accounts`)
    .doc(accountID)
    .collection(`360s`)
    .doc(current360ID)
    .collection(`groups`)
    .doc(currentGroupID)
    .collection(`reports`)
    .doc(reportType)
    .update({ updateByReview: true });
};

export const doGetQuestionnaireLabels = (accountID, questionnaireID) =>
  db
    .collection(`accounts`)
    .doc(accountID)
    .collection('questionnaires')
    .doc(questionnaireID)
    .collection(`labels`)
    .get();
