import React from 'react';

class Forward extends React.Component {
 componentDidMount(){
     window.location.href = "https://boomerang-hr.com";
  }

  render() {
    return (
      <div></div>
    )
  }
}

export default Forward;