import React, { Component } from 'react'
import { Link } from 'react-router-dom'
//
class Navigation extends Component {
  constructor(props) {
    super(props)
  }
  render() {
    let personalFeedbackLink = `/${this.props.accountID}/${this.props.userID}/`
    let feedbackRequestsLink = `/${this.props.accountID}/${this.props.userID}/feedback-requests/`
    let helpLink = `/${this.props.accountID}/${this.props.userID}/help/`
    return (
      <nav className='mw9-ns ph4-ns center h-100 flex justify-between items-center'>
        <Link className="pv2 white no-underline" to={personalFeedbackLink}>Personal Feedback</Link>
        <Link className="pv2 white no-underline" to={feedbackRequestsLink}>Feedback Requests</Link>
        <Link className="pv2 white no-underline" to={helpLink}>Help</Link>
      </nav>
    )
  }
}

export default Navigation;
