import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';

//
if (!firebase.apps.length) {
  firebase.initializeApp({
    apiKey: process.env.REACT_APP_FIREBASE_CONFIG_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_CONFIG_AUTH_DOMAIN,
    databaseURL: process.env.REACT_APP_FIREBASE_CONFIG_DATABASE_URL,
    projectId: process.env.REACT_APP_FIREBASE_CONFIG_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_CONFIG_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_CONFIG_MESSAGING_SENDER_ID
  });
}

const db = firebase.firestore();
const auth = firebase.auth();

export {
  db,
  auth
};

// export const provider = new firebase.auth.GoogleAuthProvider();
// export const auth = firebase.auth();
// export default firebase;
