import React, { Component } from 'react';
import { db } from '../firebase';
import { Link } from 'react-router-dom';
import axios from 'axios';
import Modal from './global/modal';

class ReviewerInfo extends Component {
  constructor(props) {
    super(props);
    this.closeSendingModal = this.closeSendingModal.bind(this);
    this.state = {
      accountID: this.props.accountID,
      reviewerID: this.props.reviewerID,
      userReviewers: this.props.userReviewers,
      name: this.props.name,
      relationshipKey: this.props.relationshipKey,
      email: this.props.email,
      completionPercentage: 0,
      currentGroupId: this.props.groupID,
      current360Id: this.props.current360ID,
      revieweeDetails: this.props.userDetails,
      logo: this.props.logo,
      showSendingModal: false,
      sendingError: false,
      loading: true,
    };
  }
  componentDidMount() {
    const { index, accountID } = this.props;

    db.getReviewerReviewStatus(this.state.accountID, this.state.reviewerID).then((snapshot) => {
      this.setState({
        completionPercentage: snapshot.data()['completionPercentage'],
        loading: false,
      });
    });
  }

  closeSendingModal() {
    this.setState({
      showSendingModal: false,
      sendingSuccess: false,
      sendingError: false,
      sendingComplete: false,
    });
  }

  sendEmail(e) {
    e.preventDefault();
    this.setState({
      showSendingModal: true,
      sendingComplete: false,
    });
    const reqBody = this.state;
    axios
      .post('/revieweeSendReminderEmail', {
        reqBody,
      })
      .then((res) => {
        this.setState({ sendingComplete: true, sendingSuccess: true });
      })
      .catch((err) => {
        this.setState({
          sendingError: true,
        });
      });
  }

  render() {
    if (this.state.loading) {
      return <div />;
    } else {
      return (
        <div className="flex items-center w-100 bg--pale pv3 ph4 mt2 mb3">
          <h4 className="w-20 ma0 pv1 near-black" style={{fontSize: '.8125rem'}}>
            <strong>{this.state.name}</strong>
          </h4>

          <p className="w-20 ma0 pv1 tc bl b--light-silver">
            {this.state.completionPercentage && this.state.completionPercentage === 100 ? (
              <span className="dark-green strong">Completed</span>
            ) : (
              <span className="strong" style={{color: '#DD030E'}}>Incomplete</span>
            )}
          </p>

          <p className="w-30 ma0 pv1 tc bl b--light-silver">{this.state.email}</p>
          <p className="w-30 ma0 pv1 tc bl b--light-silver">
            {
              <button
                className="btn"
                onClick={(e) => {
                  this.sendEmail(e);
                }}
              >
                Send Reminder Email
              </button>
            }
          </p>
          <Modal
            isOpen={this.state.showSendingModal}
            contentLabel="Reviewer Confirmation"
            hideCloseButton={true}
            onClose={() => this.closeSendingModal()}
            content={
              <div className="tc">
                {!this.state.sendingComplete ? <h1 className="mt0 mb2">Sending Reviewer Notification</h1> : <h1 className="mt0 mb2">Notification Complete</h1>}

                {!this.state.sendingComplete ? <div className="loading-spinner loading-spinner--lonely" /> : ''}
                {this.state.sendingError ? (
                  <p className="red">There was an error assigning one or more Reviewers, please check Reviewer details and try again</p>
                ) : (
                  ''
                )}
                {this.state.sendingSuccess ? <p>An email has been sent to your selected Reviewers notifiying them to complete a 360 on your behalf.</p> : ''}
                {this.state.sendingComplete ? (
                  <button className="btn" onClick={this.closeSendingModal}>
                    Close
                  </button>
                ) : (
                  ''
                )}
              </div>
            }
          />
        </div>
      );
    }
  }
}

export default ReviewerInfo;
