import React, { Component } from 'react';
import { db } from '../firebase';
import { Link } from 'react-router-dom';
import SiteHeader from '../components/site-header';
import SiteNav from '../components/site-nav';
import SiteFooterBrand from '../components/site-footer-brand';
import Questionnaire from '../components/questionnaire';
import { convertRelationshipTextToKey } from '../helpers';
import moment from 'moment';
import withBranding from '../components/withbranding';
const authCondition = (authUser) => !!authUser;

class Individual360Dashboard extends Component {
  constructor() {
    super();
    this.state = {
      loading: true,
      userId: null,
      questionnaireID: null,
      createdDate: null,
      deadlineDate: null,
    };
  }
  componentDidMount() {
    const {
      match: { params },
    } = this.props;
    // const get360QuestionniareIDRef = db.doGet360QuestionniareInfo(params.accountID, params.current360ID)
    let createdDate;
    let deadlineDate;
    // const currentUserRef = db.doGetCurrentUser(params.accountID, params.userID)
    db.doGetCurrentUser(params.accountID, params.userID).then((snapshot) => {
      this.setState({
        userDetails: snapshot.data(),
        accountID: params.accountID,
      });

      db.doGetCurrent360Group(params.accountID, params.current360ID, params.groupID).then((snapshot) => {
        this.setState({
          createdDate: snapshot.data()['createdDate'],
          deadlineDate: snapshot.data()['deadlineDate'],
          locked: snapshot.data().locked,
        });
        console.log(this.state.deadlineDate);

        db.doGet360QuestionniareInfo(params.accountID, params.current360ID).then((snapshot) => {
          if (snapshot.data()) {
            let this360Info = snapshot.data();
            let questionaireID = snapshot.data()['questionnaireID'];
            let current360Title = snapshot.data()['title'];

            let oneDay = 24 * 60 * 60 * 1000;
            let today = new Date();
            let twoWeeksFromCreationDate;

            console.log(this.state.deadlineDate);
            if (this.state.deadlineDate) {
              twoWeeksFromCreationDate = new Date(this.state.deadlineDate);
              console.log(this.state.deadlineDate);
              console.log(twoWeeksFromCreationDate);
            } else {
              twoWeeksFromCreationDate = new Date(this.state.createdDate);
              twoWeeksFromCreationDate.setDate(twoWeeksFromCreationDate.getDate() + 14);
            }

            // let this.state.deadlineDate = twoWeeksFromCreationDate
            this.setState({
              deadlineDate: twoWeeksFromCreationDate,
            });

            let remainingDays = 0;
            if (today < twoWeeksFromCreationDate) {
              let diffDays = Math.round(Math.abs((today.getTime() - twoWeeksFromCreationDate.getTime()) / oneDay));
              remainingDays = diffDays > 0 ? diffDays : 0;
            }

            this.setState({
              userId: params.userID,
              questionnaireID: questionaireID,
              current360Title: current360Title,
              createdDate: createdDate,
              remainingDays: remainingDays,
            });

            console.log(params.accountID, params.userID, params.current360ID, params.groupID, questionaireID);
            db.doGetSelfReviewInfo(
              params.accountID,
              params.userID,
              params.current360ID,
              params.groupID,
              questionaireID
            ).then((snapshot) => {
              this.setState({
                selfReviewInfo: snapshot.data(),
              });

              console.log(snapshot.data());

              db.getCurrentUserReviewers(
                params.accountID,
                params.current360ID,
                params.groupID,
                params.userID,
                this.props.accountBranding.relationships
              ).onSnapshot((snapshot) => {
                let reviewers = {};
                let userReviewsEmails = []; // just map emails to this for easy checking.
                let numReviewersSetup = 0;

                Object.keys(this.props.accountBranding.relationships).map((key) => {
                  reviewers[key] = {};
                });

                snapshot.docs.map((doc, i) => {
                  // console.log(doc.id);
                  console.log(doc.data());

                  // let convertedRelationshipKey
                  //
                  // if(reviewers[ doc.data()['relationship'] ]) {
                  //   convertedRelationshipKey = doc.data()['relationship']
                  // } else {
                  //   convertedRelationshipKey = convertRelationshipTextToKey( doc.data()['relationship'] )
                  // }
                  console.log(reviewers);
                  console.log(convertRelationshipTextToKey(doc.data()['relationship']));
                  console.log(doc.data()['relationship']);
                  console.log(doc.id);

                  if (reviewers[convertRelationshipTextToKey(doc.data()['relationship'])] !== undefined) {
                    reviewers[convertRelationshipTextToKey(doc.data()['relationship'])][doc.id] = doc.data();
                  }

                  if (doc.data()['email'] || doc.data()['completed']) {
                    userReviewsEmails.push(doc.data()['email']);
                    numReviewersSetup++;
                  }
                });

                this.setState({
                  userReviewers: reviewers,
                  numReviewersSetup: numReviewersSetup,
                  userReviewsEmails: userReviewsEmails,
                  loading: false,
                });

                // let userReviewers = data
                // let numReviewersSetup = 0
                //
                // Object.keys(userReviewers)
                //   .map((key) => {
                //     numReviewersSetup = numReviewersSetup + Object.keys(userReviewers[key]).length
                //   })
                //
                // this.setState({
                //   userReviewers: userReviewers,
                //   numReviewersSetup: numReviewersSetup,
                //   loading: false,
                // })
              });
            });
          }
        });
      });
    });
  }

  render() {
    const {
      match: { params },
    } = this.props;

    if (this.state.loading) {
      return (
        <div className='vh-100 vw-100 flex items-center justify-center'>
          <p>Loading</p>
        </div>
      );
    } else {
      let returnLink = `/${params.accountID}/${params.userID}/`;
      return (
        <div>
          <SiteHeader userDetails={this.state.userDetails} />
          <SiteNav accountID={params.accountID} userID={params.userID} />
          <section className='page-wrapper'>
            <div className='container container--slim'>
              <h1 className='mb0'>{this.state.current360Title}</h1>
              {this.state.remainingDays > 0 ? (
                <p className='mb4 mt1'>
                  <strong>{this.state.remainingDays}</strong> days left to complete
                </p>
              ) : (
                ''
              )}

              <h2 className='f4'>Important Information</h2>
              <ul>
                <li>
                  <p>Please complete your self-review</p>
                </li>
                <li>
                  <p>Choose the reviewers who will give you feedback</p>
                </li>
                <li>
                  <p>
                    The deadline for completing your self-review and for you reviewers to provide feedback is{' '}
                    <strong>{moment(this.state.deadlineDate).format("dddd Do MMMM 'YY")}</strong>
                  </p>
                </li>
              </ul>

              <div className='flex-ns justify-between items-center w100 bg-white shadow ph4 pv2 pv4 mv4'>
                {this.state.selfReviewInfo && this.state.selfReviewInfo.completionPercentage === 100 ? (
                  <div className='w-50-ns'>
                    <p className='mv2 mv2-ns tc tl-ns'>
                      <strong className='green'>You have completed your self-review</strong>
                    </p>
                    <p className='mv2 mv2-ns tc tl-ns'>You can still edit your answers</p>
                  </div>
                ) : (
                  <div className='w-50-ns'>
                    <h3 className='mv2 mv2-ns tc tl-ns near-black' style={{fontSize: '.8125rem', lineHeight: '1.8'}}>
                      <strong>Complete your self review</strong>
                    </h3>
                    <p className='mv2 mv2-ns tc tl-ns'>
                      <strong>
                        {this.state.selfReviewInfo ? this.state.selfReviewInfo.completionPercentage : '0'}%
                      </strong>{' '}
                      Complete
                    </p>
                  </div>
                )}
                <div className='w-50-ns'>
                  <p className='mv2 ma0-ns tc tr-ns'>
                    <Link
                      className='btn'
                      to={`/${this.state.accountID}/${this.state.userId}/360/${params.current360ID}/${params.groupID}/${this.state.questionnaireID}/`}
                    >
                      <span>
                        {this.state.selfReviewInfo && this.state.selfReviewInfo.completionPercentage === 100
                          ? 'Edit my Answers'
                          : 'Start'}
                      </span>
                    </Link>
                  </p>
                </div>
              </div>
              {this.state.locked ? (
                <div className='flex-ns justify-between items-center w100 ph3 pv2 pv1 mv4' />
              ) : (
                <div className='flex-ns justify-between items-center w100 bg-white shadow ph4 pv2 pv4 mv4'>
                  <div className='w-50-ns'>
                    <h3 className='mv2 mv2-ns tc tl-ns near-black' style={{fontSize: '.8125rem', lineHeight: '1.8'}}>
                      <strong>Choose a Reviewer</strong>
                    </h3>
                    <p className='mv2 mv2-ns tc tl-ns'>
                      You have selected{' '}
                      <strong>
                        {this.state.numReviewersSetup} Reviewer
                        {this.state.numReviewersSetup > 1 || this.state.numReviewersSetup === 0 ? 's' : ''}
                      </strong>
                      {this.state.numReviewersSetup < 3 ? ', please add at least 2 reviewers' : ''}
                    </p>
                  </div>
                  <div className='w-50-ns'>
                    <p className='mv2 ma0-ns tc tr-ns'>
                      <Link 
                        className='btn' 
                        to={`/${this.state.accountID}/${this.state.userId}/360/${params.current360ID}/${params.groupID}/reviewers/`}
                      >
                        <span>Add Reviewers</span>
                      </Link>
                    </p>
                  </div>
                </div>
              )}

              <Link className='btn btn--back mt3' to={returnLink}>
                <span>Account Dashboard</span>
              </Link>
            </div>
          </section>

          <footer className='site-footer'>
            <div className='header-logo' />
          </footer>
        </div>
      );
    }
  }
}

export default withBranding(authCondition)(Individual360Dashboard);
