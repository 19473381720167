import React, { Component } from 'react'
import { db } from '../firebase'
import { Link } from 'react-router-dom'
import SiteHeader from '../components/site-header'
import SiteNav from '../components/site-nav'
import SiteFooterBrand from '../components/site-footer-brand'
import List360 from '../components/list360'
const authCondition = (authUser) => !!authUser

class Help extends Component {
  constructor() {
    super()

    this.state = {
      userID: null,
      accountID: null,
      userLoaded: false
    }
  }

  componentDidMount() {
    const { match: { params } } = this.props;
    const currentUserRef = db.doGetCurrentUser(params.accountID, params.userID)


    this.setState({
      userID: params.userID,
      accountID: params.accountID,
    })



    if(currentUserRef) {

      currentUserRef.on('value', data => {
        if(data.val()) {
          let userObj = data.val()

          this.setState({
            userDetails: data.val(),
            userLoaded: true
          })
        }
      })
    }

  }

  render() {
    const { match: { params } } = this.props

    if (this.state.userLoaded) {
      return (
        <div>
          <SiteHeader userDetails={this.state.userDetails} />
          <SiteNav userID={this.state.userID} accountID={this.state.accountID} />
          <div className="bg-blue">
            <div className="mw7-l center pa4 ph4-l pv5-l tc">
              <h1 className="white"><span>Boomerang</span> help section</h1>
              <p className="white">Lorem ipsum dolor sit amet, est audiam volumus ea. Cum et suas laudem deserunt, ea vel prima nonumes accusamus. Mea ei omnes insolens, alia minim volutpat ad est. Sea in nusquam menandri, cu mei dicit corrumpit eloquentiam. Cum eu munere partem debitis.</p>
            </div>
          </div>
          <div className="mw8-l center pa4 ph4-l">
            <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr,  sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr,  sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.</p>
            <p>Duis autem vel eum iriure dolor in hendrerit in vulputate velit esse molestie consequat, vel illum dolore eu feugiat nulla facilisis at vero eros et accumsan et iusto odio dignissim qui blandit praesent luptatum zzril delenit augue duis dolore te feugait nulla facilisi. Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat.</p>
          </div>

          <SiteFooterBrand />
        </div>
      )

    } else {
      return (
        <div className="vh-100 vw-100 flex items-center justify-center">
          Loading
        </div>
      )

    }
  }
}

export default Help
