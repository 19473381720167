import React from 'react';
import { withRouter } from 'react-router-dom'
import BrandingContext from './brandingContext'
import { Link } from 'react-router-dom'
import { db, firebase } from '../firebase'
import loadingGif from '../loading.gif'
import { Helmet } from "react-helmet"
import logo from '../logo.svg'
// import {Helmet} from "react-helmet"

const withBranding = (authCondition) => (Component) => {
  class WithBranding extends React.Component {
    constructor(props) {
      super(props)
      this.state = {
        loading: true,
        error: false,
        accountBranding: null
      };
    }

    componentDidMount() {
      firebase.auth.onAuthStateChanged(authUser => {
        if (authCondition(authUser)) {
          if(this.props.match.params.accountID && this.props.match.params.current360ID) {
            db.doGetAccountBrandingAndRelationships(this.props.match.params.accountID, this.props.match.params.current360ID).then((data) => {
              console.log(data);
              if(data) {
                this.setState({
                  accountBranding: data,
                  loading: false
                })
              }
            })
          } else {
            if(this.props.match.params.current360ID) {
              db.doGetAccountBranding(this.props.match.params.accountID, this.props.match.params.current360ID).then((snapshot) => {
                if(snapshot.data()) {
                  this.setState({
                    accountBranding: snapshot.data(),
                    loading: false
                  })
                }
              })
            } else {
              db.doGetGenericAccountBranding(this.props.match.params.accountID).then((snapshot) => {
                if(snapshot.data()) {
                  this.setState({
                    accountBranding: snapshot.data(),
                    loading: false
                  })
                }
              })
            }
          }
        }
      })
    }

    render() {
      const { accountBranding } = this.state;
      const { loading, error } = this.state;
      // console.log("accountData");
      // console.log(accountData);
      if(loading) {
        return (
          <div className="page-loading-state">
            <img src={ loadingGif } />
          </div>
        )
      }
      if(error) {
        return (
          <div className="vh-100 vw-100 flex items-center justify-center">
            <div>
              <p>There was an error loading this page.</p>
              <p><Link className="btn" to="/dashboard/">Return to dashboard</Link></p>
            </div>
          </div>
        )
      }
      return (
        <BrandingContext.Provider>
          {
            (this.state.accountBranding && this.state.accountBranding.primarycolour != "#0051ff") ?
              <Helmet>
                <style>{`
                  .site-header {
                    background: ${ ( !this.state.accountBranding.logo.includes("boomerang-logo") ) ? '#FFFFFF' : '#0051ff' };
                    color: ${ ( !this.state.accountBranding.logo.includes("boomerang-logo") ) ? '#333333' : '#FFFFFF' };
                  }
                  .site-header--nav {
                    box-shadow: 0 6px 6px 0px rgba(0,0,0,0.15);
                  }
                  h1,h2,h3,h4,h5,h6 {
                    color: #333E5B;
                  }
                  .welcome {
                    color: ${ (this.state.accountBranding.resetbrand) ? '#333E5B;' : '#0051ff'};
                  }
                  .welcome:before {
                    background: ${ (this.state.accountBranding.resetbrand) ? 'transparent;' : 'transparent;'};
                  }
                  .btn:not(.btn--white) {
                    // background-color: #333E5B;
                    background-color: ${(this.state.accountBranding.buttoncolour) ? this.state.accountBranding.buttoncolour : this.state.accountBranding.primarycolour};
                  }
                  .site-header__nav-btn {
                    color: ${this.state.accountBranding.secondarycolour};
                    fill: ${this.state.accountBranding.secondarycolour};
                  }
                  .site-header__nav-btn span:before {
                    background: ${this.state.accountBranding.secondarycolour};
                  }
                  body {
                    color: ${this.state.accountBranding.tertiarycolour};
                  }
                  .header-logo {
                    background-image: url(${ (this.state.accountBranding.logo.includes("boomerang-logo") ? logo : this.state.accountBranding.logo ) });
                  }
                  .site-footer {
                    background: ${ ( !this.state.accountBranding.logo.includes("boomerang-logo") ) ? '#FFFFFF;' : '#3b4e7e'};
                  }
                  .site-footer .btn {
                    color: ${ ( !this.state.accountBranding.logo.includes("boomerang-logo") ) ? '#FFFFFF;' : '#0051ff;'};
                    background: ${ ( !this.state.accountBranding.logo.includes("boomerang-logo") ) ? '#3b4e7e;' : '#FFFFFF'};
                  }
                  `}
                </style>
              </Helmet>
            : ''
          }

          <Component accountBranding={ accountBranding } {...this.props} />
        </BrandingContext.Provider>
      );

      // return (
      //   <User360sContext.Provider>
      //     <Component foo="bar" {...this.props} />
      //   </User360sContext.Provider>
      // );
    }
  }

  return WithBranding;

}

export default withBranding;
