import React, { Component } from 'react';
import { db } from '../firebase';
import { Link } from 'react-router-dom';

class List360 extends Component {
  constructor() {
    super();
    this.state = {
      this360Info: null,
      current360ID: null,
      loading: true,
    };
  }
  componentDidMount() {
    const { index, accountID } = this.props;
    // const current360Info = db.get360Info(accountID, this.props.index)

    db.get360Info(accountID, this.props.index).then((snapshot) => {
      console.log(snapshot.data());
      let this360Info = snapshot.data();

      if (this360Info) {
        let oneDay = 24 * 60 * 60 * 1000;
        let today = new Date();
        let twoWeeksFromCreationDate;

        // if(this360Info['groups'][this.props.groupID]['deadlineDate']) {
        //   twoWeeksFromCreationDate = new Date(this360Info['groups'][this.props.groupID]['deadlineDate'])
        // } else {
        //   twoWeeksFromCreationDate = new Date(this360Info['groups'][this.props.groupID]['createdDate'])
        //   twoWeeksFromCreationDate.setDate(twoWeeksFromCreationDate.getDate()+14)
        // }

        if (this.props.deadlineDate) {
          let deadlineDate = new Date(this.props.deadlineDate);
          console.log(deadlineDate);
          // console.log(deadlineDate);
          // Check if still with deadline date
          if (deadlineDate > today) {
            let diffDays = Math.round(Math.abs((today.getTime() - deadlineDate.getTime()) / oneDay));
            console.log(diffDays);

            let remainingDays = diffDays > 0 ? diffDays : 0;

            this.setState({
              remainingDays: remainingDays,
            });
          } else {
            this.setState({
              remainingDays: null,
            });
          }
        } else {
          this.setState({
            remainingDays: 14,
          });
        }
      }
      db.getCurrentGroupDetails(this.props.accountID, this.props.index, this.props.groupID)
        .then((groupRes) => {
          console.log(groupRes.data());
          this.setState({
            this360Info: this360Info,
            loading: false,
            groupDetails: groupRes.data(),
          });
        })
        .catch((err) => {
          console.log(err);
          this.setState({
            this360Info: this360Info,
            loading: false,
            groupDetails: {},
          });
        });
    });
  }
  render() {
    if (this.state.loading) {
      return <div></div>;
    } else {
      let selfReviewLink = `360/${this.props.index}/${this.props.groupID}/${this.props.questionaireID}/`;
      let addReviewersLink = `360/${this.props.index}/${this.props.groupID}/reviewers/`;

      // 360/-LsukygE1L0t2l2QOC9L/-LsumzDb7QZPneYF4Ksq/-LsukykVGwRTPf3PgvzX/
      if (this.state.this360Info) {
        return (
          <div className='individual-360-strip mb4'>
            <div>
              <h2 className='reset-mar individual-360-strip__title individual-360-strip__title--lfarge ma0'>
                <strong>{this.state.this360Info.title}</strong>
              </h2>
              {this.props.completionPercentage > 0 ? (
                <p className='mv2 mv1-ns tc tl-ns'>
                  <strong>{this.props.completionPercentage}%</strong> complete
                </p>
              ) : (
                <p className='mv2 mv1-ns tc tl-ns dark-red'>
                  <strong>You havent completed your self-review</strong>
                </p>
              )}
              {this.state.remainingDays ? (
                <p className='mv2 mv1-ns tc tl-ns'>
                  You have <strong>{this.state.remainingDays}</strong> days left to complete
                </p>
              ) : (
                <p className='mv2 mv1-ns tc tl-ns'>The deadline for completing this self-review has passed</p>
              )}
            </div>
            {this.state.remainingDays ? (
              <div className='individual-360-strip__actions'>
                {this.props.completionPercentage < 100 ? (
                  <Link className='btn' to={selfReviewLink}>
                    <span>Complete Self Review</span>
                  </Link>
                ) : this.state.groupDetails.locked === false ? (
                  <Link className='btn' to={`360/${this.props.index}/${this.props.groupID}/`}>
                    <span>View project</span>
                  </Link>
                ) : (
                  <Link
                    className='btn'
                    to={`360/${this.props.index}/${this.props.groupID}/${this.state.this360Info.questionnaireID}/`}
                  >
                    <span>Edit my Answers</span>
                  </Link>
                )}
              </div>
            ) : (
              ''
            )}
          </div>
        );
      } else {
        return <div></div>;
      }
    }
  }
}
//

export default List360;
