import React, { Component } from 'react'
import { Link } from 'react-router-dom'

class SiteNav extends Component {
  constructor(props) {
    super(props)

  }
  render() {
    let personalFeedbackLink = `/${this.props.accountID}/${this.props.userID}/`
    let feedbackRequestsLink = `/${this.props.accountID}/${this.props.userID}/feedback-requests/`
    let helpLink = `/${this.props.accountID}/${this.props.userID}/help/`
    return (
      <header className='site-header site-header--nav'>
        <div className='container'>
          <nav className='site-header__row site-header__row--start'>
            <Link className="site-header__nav-btn" to={personalFeedbackLink}>My Feedback</Link>
            <Link className="site-header__nav-btn" to={feedbackRequestsLink}>Feedback Requests</Link>
          </nav>
        </div>
      </header>
    );
  }
}

export default SiteNav
