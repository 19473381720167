import React, { Component } from 'react'
import logo from '../logo.svg'

class NoMatch extends Component {
  render() {
    return (
      <main>
        <div className="error-404 bg-blue">
          <img className="header-logo" src={logo} alt='Boomerang Logo' />
          <h1 className="white mt4">404 Error - Page not found</h1>
        </div>
      </main>
    );
  }
}

export default NoMatch;
