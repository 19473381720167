import React, { Component } from 'react';
import Question from '../components/question';

// let questionnaireClass

class Questionnaire extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    const { scaleLabels, reverseOrderedLabelIDs } = this.props;
    const scaleCount = reverseOrderedLabelIDs.length
    let questionnaireClass = `bg-white mv4 ph4 pv4 shadow o-50`;
    if (this.props.goalActive) {
      questionnaireClass = `bg-white mv4 ph4 pv4 shadow`;
    }

    let questions = [];
    let legacyMode = false;
    Object.keys(this.props.questions).map((doc) => {
      if (this.props.questions[doc].key === undefined) {
        let dataObj = new Object();
        dataObj = this.props.questions[doc];
        dataObj.legacyKey = doc;
        questions.push(dataObj);
      } else {
        questions.push(this.props.questions[doc]);
      }
    });
    // console.log(questions);
    const toOrder = Object.keys(questions).map((i) => questions[i]);
    // console.log(toOrder);
    //it will always check the first one in the arrray which will always be the oldest as they are saved in date orderr
    if (toOrder.length != 0) {
      if (toOrder[0].sortOrder === undefined) {
        legacyMode = true;
      }
    }

    // console.log(legacyMode);

    if (legacyMode === false) {
      toOrder.sort((a, b) => {
        if (a.sortOrder < b.sortOrder) {
          return -1;
        }
        if (a.sortOrder > b.sortOrder) {
          return 1;
        }
      });
    }

    let handleQuestions = {
      questions: toOrder,
      legacyMode: legacyMode,
    };
    // console.log(handleQuestions);
    return (
      <div className={questionnaireClass}>
        {Object.keys(handleQuestions.questions).map((key, i) => {
          return (
            <div key={`questionnaire_${key}`}>
              {i == 0 && this.props.questionnaireType == 'Goal Tracker' ? (
                <div className='flex-l justify-start items-end'>
                  <div className='w-40 mb3'>
                    <h2 className='mb0 f4'>{this.props.title}</h2>
                    <p>{this.props.description}</p>
                  </div>
                </div>
              ) : (
                ''
              )}

              {i == 0 && 
              handleQuestions.questions[key].answerType == 'MultiChoice' && 
              this.props.questionnaireType != 'Goal Tracker' ? (
                <div className='flex-l justify-center items-end'>
                  <div className='w-40 mb5'>
                    <h2 className='mb0 f4'>{this.props.title}</h2>
                    <p>{this.props.description}</p>
                  </div>
                  <div className='w-60 flex-l justify-around items-end tc ml2'>
                    {reverseOrderedLabelIDs.map((labelID, i) => {
                      const {text, frequency} = scaleLabels[labelID]
                      return (
                        <p
                          key={labelID}
                          className={`questionnaire-rating-label w-100-l dn db-l ${i < scaleCount-1 ? 'has-tooltip' : ''}`}
                          data-tooltip={i < scaleCount-1 ? frequency : ''}
                        >
                          {text}
                          <br />
                          <strong></strong>
                        </p>
                      );
                    })}
                  </div>
                </div>
              ) : (
                ''
              )}

              {i == 0 && 
              handleQuestions.questions[key].answerType == 'FreeText' && 
              this.props.questionnaireType != 'Goal Tracker' ? (
                <div className='flex-l justify-start items-end'>
                  <div className='w-40 mb3'>
                    <h2 className='mb0 f4'>{this.props.title}</h2>
                    <p>{this.props.description}</p>
                  </div>
                </div>
              ) : (
                ''
              )}
              {handleQuestions.legacyMode ? (
                <Question
                  key={handleQuestions.questions[key].legacyKey}
                  index={handleQuestions.questions[key].legacyKey + this.props.index}
                  section={this.props.index}
                  question={handleQuestions.questions[key]}
                  answer={
                    this.props.answers && this.props.answers[handleQuestions.questions[i].id]
                      ? this.props.answers[handleQuestions.questions[i].id]
                      : null
                  }
                  legacy={true}
                  questionUpdated={this.props.questionUpdated}
                  questionnaireType={this.props.questionnaireType}
                  questionActive={this.props.goalActive}
                  scaleLabels={scaleLabels}
                  reverseOrderedLabelIDs={reverseOrderedLabelIDs}
                />
              ) : (
                <Question
                  key={handleQuestions.questions[key].id}
                  index={handleQuestions.questions[key].id + this.props.index}
                  section={this.props.index}
                  question={handleQuestions.questions[key]}
                  answer={
                    this.props.answers && this.props.answers[handleQuestions.questions[i].id]
                     ? this.props.answers[handleQuestions.questions[i].id]
                     : null
                  }
                  legacy={false}
                  questionUpdated={this.props.questionUpdated}
                  questionnaireType={this.props.questionnaireType}
                  questionActive={this.props.goalActive}
                  scaleLabels={scaleLabels}
                  reverseOrderedLabelIDs={reverseOrderedLabelIDs}
                />
              )}

              {/* {i == 0 &&
              Object.keys(handleQuestions.questions).length != 1 &&
              handleQuestions.questions[key].answerType == 'FreeText' &&
              this.props.questionnaireType != 'Goal Tracker' ? (
                <div className='flex-l justify-end items-end'>
                  <div className='w-60 flex-l justify-around items-end tc ml2'>
                    <p
                      className='questionnaire-rating-label w-100-l dn db-l has-tooltip'
                      data-tooltip='Over 90% of the time'
                    >
                      Frequently if not always
                      <br />
                      <strong />
                    </p>
                    <p
                      className='questionnaire-rating-label w-100-l dn db-l has-tooltip'
                      data-tooltip='60% - 89% of the time'
                    >
                      Quite <br />
                      often
                      <br />
                      <strong />
                    </p>
                    <p
                      className='questionnaire-rating-label w-100-l dn db-l has-tooltip'
                      data-tooltip='36% - 59% of the time'
                    >
                      Some of <br />
                      the time
                      <br />
                      <strong />
                    </p>
                    <p
                      className='questionnaire-rating-label w-100-l dn db-l has-tooltip'
                      data-tooltip='1% - 35% of the time'
                    >
                      Rarely
                      <br />
                      <strong />
                    </p>
                    <p className='questionnaire-rating-label w-100-l dn db-l has-tooltip' data-tooltip='0% of the time'>
                      Never
                      <br />
                      <strong />
                    </p>
                    <p className='questionnaire-rating-label w-100-l dn db-l'>
                      Unable to comment
                      <br />
                      <strong />
                    </p>
                  </div>
                </div>
              ) : (
                ''
              )} */}
            </div>
          );
        })}

        {this.props.questionnaireType == 'Goal Tracker' ? (
          <button
            disabled={!this.props.goalActive}
            onClick={(e) => this.props.showSavedModal(this.props.index)}
            className='btn'
          >
            Save this Goal
          </button>
        ) : (
          ''
        )}
      </div>
    );
  }
}

// answer={this.props.answers[key]}
export default Questionnaire;
