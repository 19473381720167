export function convertRelationships(relationship) {
  switch(relationship) {
    case 'DR':
      return 'Direct Report';
    case 'LM':
      return 'Line Manager';
    case 'P':
      return 'Peer';
    case 'O':
      return 'Other';
    default:
      return 'None';

  }
}

export function convertRelationshipTextToKey(relationship) {
  switch(relationship) {
    case 'Direct Report':
      return 'DR';
    case 'Line Manager':
      return 'LM';
    case 'Peer':
      return 'P';
    case 'Other':
      return 'O';
    default:
      return relationship;
  }
}

export const sleep = async (time) => {
  return new Promise((resolve) => setTimeout(resolve, time));
};

export const projectTypeText = (projectType, standard360, teamAssessment) => {
  if (projectType === '360') {
    return standard360;
  } else {
    return teamAssessment;
  }
};