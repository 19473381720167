import React, { Component } from 'react'
import Navigation from './navigation'
import logo from '../logo.svg'

class SiteHeaderReviewer extends Component {
  constructor(props) {
    super(props)
  }
  render() {
    return (
      <header className='site-header'>
        <div className='container'>
          <div className='site-header__row'>
            <p className='ma0 pa0 dn dib-l'>Hi,&nbsp;<strong>{this.props.reviewerDetails.name.split(/ (.*)/)[0]}</strong>{ (this.props.reviewerDetails.email) ? ' - ' + this.props.reviewerDetails.email : '' }</p>

            <p className='ma0 pa0 dn'>( { this.props.completionPercentage }% Complete )</p>
            <div className="header-logo"></div>
          </div>
        </div>
      </header>
    );
  }
}

export default SiteHeaderReviewer;
