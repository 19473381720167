import React, { Component } from 'react';
import { db } from '../firebase';
import { Link } from 'react-router-dom';
import SiteHeader from '../components/site-header';
import SiteNav from '../components/site-nav';
import SiteFooterBrand from '../components/site-footer-brand';
import ListReview from '../components/list-review';
import withBranding from '../components/withbranding';
const authCondition = (authUser) => !!authUser;

class FeedbackRequests extends Component {
  constructor() {
    super();

    this.state = {
      accountID: null,
      userId: null,
      my360IDs: null,
      questionnaireIDs: null,
      currentFeedback: null,
      completedReviewerRequests: null,
      submittedFeedback: null,
      previousFeedback: null,
      userLoaded: false,
    };
  }

  componentDidMount() {
    const {
      match: { params },
    } = this.props;
    const currentUserRef = db.doGetCurrentUser(params.accountID, params.userID);

    this.setState({
      userId: params.userID,
      accountID: params.accountID,
    });

    if (currentUserRef) {
      db.doGetCurrentUser(params.accountID, params.userID).then((snapshot) => {
        if (snapshot.data()) {
          this.setState({
            userDetails: snapshot.data(),
          });

          db.getAllUserReviewerRequests(params.accountID, snapshot.data()['email']).then((snapshot) => {
            // let activeReviewerRequests = data.val()
            let activeReviewerRequests = {};
            let completedReviewerRequests = {};
            let countReviewers = 0;
            let receiverSnapshot = snapshot.docs;

            if (!receiverSnapshot.length) {
              this.setState({
                activeReviewerRequests: false,
                completedReviewerRequests: false,
                userLoaded: true,
              });
            } else {
              receiverSnapshot.map((doc) => {
                countReviewers++;

                db.getReviewerReviewStatus(this.state.accountID, doc.id).then((snapshot) => {
                  if (snapshot.data()['completionPercentage'] && snapshot.data()['completionPercentage'] == 100) {
                    // activeReviewerRequests[doc.id]["completionPercentage"] = snapshot.data()["completionPercentage"]
                    completedReviewerRequests[doc.id] = doc.data();
                    completedReviewerRequests[doc.id]['completionPercentage'] = snapshot.data()['completionPercentage'];
                  } else {
                    activeReviewerRequests[doc.id] = doc.data();
                  }
                  db.checkForReviewerOnly(this.state.accountID, this.state.userId).then((docs) => {
                    console.log(docs.empty);
                    if (docs.empty) {
                    }
                  });
                  if (countReviewers == receiverSnapshot.length) {
                    this.setState({
                      activeReviewerRequests: activeReviewerRequests ? activeReviewerRequests : false,
                      completedReviewerRequests: completedReviewerRequests ? completedReviewerRequests : false,
                      userLoaded: true,
                    });
                  }
                });
              });
            }

            // console.log(activeReviewerRequests);

            // getReviewerReviewStatus

            // db.getSelfReviewCompletionPercentage(params.accountID, params.userID, current360ID, groupID, questionaireID, "self-review").then((currentCompletionPercentage) => {

            // this.setState({
            //   activeReviewerRequests: (activeReviewerRequests) ? activeReviewerRequests : false,
            //   userLoaded: true
            // })
          });
        } else {
          console.log('!');
          // userID for self-reviewer not found:
          db.doGetCurrentReviewerRef(params.accountID, params.userID).then((snapshot) => {
            if (!snapshot.data()) {
              // If reviewer ID doesnt exist:
              // this.props.history.push(`/404/`);
            } else {
              let reviewerDetails = snapshot.data();
              this.setState({
                userDetails: reviewerDetails,
              });

              db.getAllUserReviewerRequests(params.accountID, snapshot.data()['email']).then((snapshot) => {
                let activeReviewerRequests = {};
                let completedReviewerRequests = {};
                let countReviewers = 0;
                let receiverSnapshot = snapshot.docs;

                if (!receiverSnapshot.length) {
                  this.setState({
                    activeReviewerRequests: false,
                    completedReviewerRequests: false,
                    userLoaded: true,
                  });
                } else {
                  console.log(receiverSnapshot);
                  receiverSnapshot.map((doc) => {
                    countReviewers++;

                    db.getReviewerReviewStatus(this.state.accountID, doc.id).then((snapshot) => {
                      if (snapshot.data()['completionPercentage'] && snapshot.data()['completionPercentage'] == 100) {
                        // activeReviewerRequests[doc.id]["completionPercentage"] = snapshot.data()["completionPercentage"]
                        completedReviewerRequests[doc.id] = doc.data();
                        completedReviewerRequests[doc.id]['completionPercentage'] = snapshot.data()['completionPercentage'];
                      } else {
                        activeReviewerRequests[doc.id] = doc.data();
                      }
                      db.checkForReviewerOnly(this.state.accountID, this.state.userId).then((docs) => {
                        console.log(docs.empty);
                        if (docs.empty) {
                        }
                      });
                      if (countReviewers == receiverSnapshot.length) {
                        this.setState({
                          activeReviewerRequests: activeReviewerRequests ? activeReviewerRequests : false,
                          completedReviewerRequests: completedReviewerRequests ? completedReviewerRequests : false,
                          userLoaded: true,
                        });
                      }
                    });
                  });
                }
              });
            }
          });
        }
      });
    }
  }

  render() {
    const {
      match: { params },
    } = this.props;

    if (this.state.userLoaded) {
      return (
        <div>
          <SiteHeader userDetails={this.state.userDetails} />
          <SiteNav accountID={params.accountID} userID={params.userID} />
          <div className='container container--slim'>
            <section className='welcome'>
              <div className='container'>
                <div className='welcome__row'>
                  <h1>
                    <span>Welcome to your</span> requests for feedback dashboard
                  </h1>
                  <p>This page will display all the people/teams that have asked you to provide them with feedback.</p>
                </div>
              </div>
            </section>
            <section className='page-wrapper'>
              {this.state.activeReviewerRequests ? (
                <div>
                  <h2 className='mb0'>Awaiting Feedback</h2>
                  <p className='mb4 mt1'>The following people/teams have requested you to provide them with feedback:</p>

                  {Object.keys(this.state.activeReviewerRequests)
                    .reverse()
                    .map((key) => {
                      if (this.state.activeReviewerRequests[key].emailRecieved) {
                        return <ListReview key={key} index={key} accountID={params.accountID} reviewInfo={this.state.activeReviewerRequests[key]} />;
                      }
                    })}
                </div>
              ) : (
                <p>You don't currently have any active Feedback Requests</p>
              )}

              {this.state.completedReviewerRequests && Object.keys(this.state.completedReviewerRequests).length > 0 ? (
                <div className='pt4'>
                  <h2 className='mb0'>Completed Feedback</h2>
                  <p className='mb4 mt1'>You have completed the following user/team requests for feedback:</p>

                  {Object.keys(this.state.completedReviewerRequests)
                    .reverse()
                    .map((key) => {
                      if (this.state.completedReviewerRequests[key].emailRecieved || this.state.completedReviewerRequests[key].completionPercentage === 100) {
                        return <ListReview key={key} index={key} accountID={params.accountID} reviewInfo={this.state.completedReviewerRequests[key]} />;
                      }
                    })}
                </div>
              ) : (
                ''
              )}
            </section>
          </div>

          <SiteFooterBrand />
        </div>
      );
    } else {
      return <div className='vh-100 vw-100 flex items-center justify-center'>Loading</div>;
    }
  }
}

export default withBranding(authCondition)(FeedbackRequests);
