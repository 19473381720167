import React, { Component } from 'react';
import { db } from '../firebase';
import { Link } from 'react-router-dom';
import SiteHeader from '../components/site-header';
import SiteNav from '../components/site-nav';
import SiteFooterReviewer from '../components/site-footer-reviewer';
import Questionnaire from '../components/questionnaire';
import Modal from '../components/global/modal';
import withBranding from '../components/withbranding';
const authCondition = (authUser) => !!authUser;

class SelfReview extends Component {
  constructor() {
    super();

    this.questionUpdated = this.questionUpdated.bind(this);
    this.updateCompletionPercentage = this.updateCompletionPercentage.bind(this);
    this.showSavedModal = this.showSavedModal.bind(this);

    this.state = {
      accountID: null,
      userID: null,
      accountBranding: null,
      questionnaireID: null,
      questionnaireType: null,
      userDetails: null,
      completionPercentage: 0,
      questions: null,
      requiredQuestionsList: {},
      questionsLoaded: false,
      showSavedModal: false,
      answers: null,
      answersLoaded: false,
    };
  }
  componentDidMount() {
    const {
      match: { params },
    } = this.props;

    // const currentUserRef = db.doGetCurrentUser(params.accountID, params.userID)
    // const questionnaireRef = db.doGetQuestions(params.accountID, params.questionnaireID)
    // const answersRef = db.doGetAnswers(params.accountID, params.userID, params.current360ID, params.groupID, params.questionnaireID, "self-review")

    this.setState({
      userID: params.userID,
      accountID: params.accountID,
      questionnaireID: params.questionnaireID,
      current360ID: params.current360ID,
      currentGroupID: params.groupID,
      currentGoalID: params.goalID,
    });

    db.doGetCurrentUser(params.accountID, params.userID).then((snapshot) => {
      if (snapshot.data()) {
        this.setState({
          userDetails: snapshot.data(),
        });
      }
    });

    db.doGetQuestionnaireType(params.accountID, params.questionnaireID).then((snapshot) => {
      let questionnaireType = 'self-review';
      if (snapshot.data()) {
        questionnaireType = snapshot.data()['type'];
      }

      db.doGetQuestionnaireSections(params.accountID, params.questionnaireID).then((snapshot) => {
        if (!snapshot.empty) {
          let sections = {};
          let sectionNum = 0;
          let numSections = snapshot.docs.length;
          snapshot.docs.map((doc) => {
            let sectionID = doc.id;
            if (doc.data()['title']) {
              sections[sectionID] = doc.data();
            }

            db.doGetQuestions(params.accountID, params.questionnaireID, sectionID).then((snapshot) => {
              sectionNum++;
              let questions = {};
              let legacyMode = false;
              if (sections[sectionID]) {
                snapshot.docs.map((doc) => {
                  if (doc.data().key === undefined) {
                    let dataObj = new Object();
                    dataObj = doc.data();
                    dataObj.id = doc.id;
                    dataObj.legacyKey = doc.id;
                    questions[doc.id] = dataObj;
                  } else {
                    questions[doc.id] = doc.data();
                  }
                  // questions[doc.id] = doc.data();
                });

                const toOrder = Object.keys(questions).map((i) => questions[i]);
                //it will always check the first one in the arrray which will always be the oldest as they are saved in date orderr
                if (toOrder.length != 0) {
                  if (toOrder[0].sortOrder === undefined) {
                    legacyMode = true;
                  }
                }

                if (legacyMode === false) {
                  toOrder.sort((a, b) => {
                    if (a.sortOrder < b.sortOrder) {
                      return -1;
                    }
                    if (a.sortOrder > b.sortOrder) {
                      return 1;
                    }
                  });
                }
                let arrayToObj = {};
                for (let i = 0; i < toOrder.length; i++) {
                  arrayToObj[toOrder[i].id] = toOrder[i];
                }

                sections[sectionID]['questions'] = arrayToObj;
              }

              if (sectionNum == numSections) {
                // section object complete

                let requiredQuestionsList = {};
                // Get pure list of all quesions
                Object.keys(sections).map((sectionKey, v) => {
                  Object.assign(requiredQuestionsList, sections[sectionKey]['questions']);
                });

                // Remove all non-required questions
                Object.keys(requiredQuestionsList).map((i, v) => {
                  if (requiredQuestionsList[i]['required'] == 'false') {
                    delete requiredQuestionsList[i];
                  }
                });

                db.doGetCurrent360Group(params.accountID, params.current360ID, params.groupID).then((snapshot) => {
                  this.setState({
                    questions: sections,
                    requiredQuestionsList: requiredQuestionsList,
                    questionnaireType: questionnaireType,
                    questionsLoaded: true,
                    groupSettings: snapshot.data(),
                  });
                  this.updateCompletionPercentage();
                });
              }
            });
          });
        }
      });
    });

    db.doGetQuestionnaireSections(params.accountID, params.questionnaireID).then((snapshot) => {
      let sections = {};
      if (!snapshot.empty) {
        let sectionNum = 0;
        let numSections = snapshot.docs.length;
        snapshot.docs.map((doc) => {
          let sectionID = doc.id;
          sections[sectionID] = doc.data();
          sectionNum++;
          db.doGetAnswers(params.accountID, params.userID, params.current360ID, params.groupID, params.questionnaireID, 'self-review', sectionID).onSnapshot(
            (snapshot) => {
              if (snapshot.data()) {
                sections[sectionID]['answers'] = snapshot.data();
              }

              if (sectionNum == numSections) {
                let scaleLabels = {};
                let reverseOrderedLabelIDs = []

                db.doGetQuestionnaireLabels(params.accountID, params.questionnaireID).then((res) => {
                  if (res.docs.length > 0) {
                    res.docs.map((doc) => {
                      scaleLabels[doc.id] = doc.data();
                      reverseOrderedLabelIDs.push(doc.id);
                    });
                  } else {
                    // adds legacy default labels
                    scaleLabels = {
                      rating0: { score: 0, text: 'Unable to comment', frequency: '', sortOrder: 0 },
                      rating1: { score: 1, text: 'Never', frequency: '0% of the time', sortOrder: 1 },
                      rating2: { score: 2, text: 'Rarely', frequency: '1% - 35% of the time', sortOrder: 2 },
                      rating3: { score: 3, text: 'Some of the time', frequency: '36% - 59% of the time', sortOrder: 3 },
                      rating4: { score: 4, text: 'Quite often', frequency: '60% - 89% of the time', sortOrder: 4 },
                      rating5: { score: 5, text: 'Frequently if not always', frequency: 'over 90% of the time', sortOrder: 5 },
                    };
                    reverseOrderedLabelIDs = Object.keys(scaleLabels)
                  }

                  reverseOrderedLabelIDs
                    .sort((a,b) => {
                      return scaleLabels[a].sortOrder - scaleLabels[b].sortOrder
                    })
                    .reverse()

                  this.setState({
                    answers: sections,
                    scaleLabels,
                    reverseOrderedLabelIDs,
                    // completionPercentage: data.val()['completionPercentage'],
                    answersLoaded: true,
                  });
                });
              }
            }
          );
        });
      }
    });
  }

  questionUpdated(sectionID, questionID, answer) {
    // update Answer
    // const updateAnswerRef = db.doUpdateAnswer(this.state.accountID, this.state.userID, this.state.current360ID, this.state.currentGroupID, this.state.questionnaireID, "self-review", sectionID, questionID, answer)
    db.doUpdateAnswer(
      this.state.accountID,
      this.state.userID,
      this.state.current360ID,
      this.state.currentGroupID,
      this.state.questionnaireID,
      'self-review',
      sectionID,
      questionID,
      answer
    )
      .then(() => {
        // On complete, calculate completion percentage
        this.updateCompletionPercentage();
      })
      .catch((error) => {
        console.log('Data could not be saved.' + error);
      });
  }

  showSavedModal(sectionID) {
    db.doUpdateSectionStatus(
      this.state.accountID,
      this.state.userID,
      this.state.current360ID,
      this.state.currentGroupID,
      this.state.questionnaireID,
      'self-review',
      sectionID
    );
    this.setState({
      showSavedModal: true,
    });
  }

  updateCompletionPercentage() {
    let answersList = {};
    let requiredQuestionsComplete = 0;
    let numberOfRequiredQuestions = Object.keys(this.state.requiredQuestionsList).length;

    Object.keys(this.state.answers).map((section, v) => {
      Object.assign(answersList, this.state.answers[section]['answers']);
    });

    Object.keys(this.state.requiredQuestionsList).map((key) => {
      if (key in answersList) {
        requiredQuestionsComplete++;
      }
    });

    // Update completion % on DB
    let completionPercentage = Math.floor((requiredQuestionsComplete * 100) / numberOfRequiredQuestions);

    db.doUpdateCompletionPercentage(
      this.state.accountID,
      this.state.userID,
      this.state.current360ID,
      this.state.currentGroupID,
      this.state.questionnaireID,
      'self-review',
      completionPercentage
    )
      .then(() => {
        // On complete update button
        if (completionPercentage === 100) {
          db.setReviewToCompleted(this.state.accountID, this.state.current360ID, this.state.currentGroupID, this.state.userID, null);
          this.setState({
            completionPercentage: completionPercentage,
          });
        }
      })
      .catch((error) => {
        console.log('Data could not be saved.' + error);
      });
  }

  render() {
    const {
      match: { params },
    } = this.props;
    if (this.state.questionsLoaded && this.state.answersLoaded) {
      let dashboardLink = `/${params.accountID}/${this.state.userID}/360/${this.state.current360ID}/${this.state.currentGroupID}/reviewers/`;
      return (
        <main>
          <SiteHeader userDetails={this.state.userDetails} completionPercentage={this.state.completionPercentage} />
          {this.state.questionnaireType == 'Goal Tracker' ? '' : <SiteNav accountID={params.accountID} userID={params.userID} />}

          <section className='page-wrapper'>
            <div className='container container--slim'>
              {this.state.questionnaireType == 'Goal Tracker' ? (
                <div>
                  <h1 className='mb0'>'My Goals'</h1>
                </div>
              ) : (
                <div>
                  <h1 className='mb0'>Complete your self review</h1>
                  <div className='w-80 mt3'>
                    <p>When rating your own behaviour or effectiveness, try to be as objective as possible, without being unduly modest</p>
                    <p>Where possible, give as many concrete examples as you can, to support your ratings</p>
                    <p>Consider your behaviour or effectiveness over an extended period, not just the most recent events that come quickly to mind</p>
                  </div>
                </div>
              )}

              {Object.keys(this.state.questions).map((key, i) => {
                // Check question exists
                if (this.state.questions[key].questions) {
                  return (
                    <Questionnaire
                      key={key}
                      index={key}
                      title={this.state.questions[key].title}
                      description={this.state.questions[key].description}
                      questions={this.state.questions[key].questions}
                      answers={this.state.answers[key] ? this.state.answers[key].answers : null}
                      questionUpdated={this.questionUpdated}
                      questionnaireType={this.state.questionnaireType}
                      showSavedModal={this.showSavedModal}
                      goalActive={this.state.currentGoalID == i + 1 || this.state.currentGoalID == null ? true : false}
                      scaleLabels={this.state.scaleLabels}
                      reverseOrderedLabelIDs={this.state.reverseOrderedLabelIDs}
                    />
                  );
                } else {
                  return false;
                }
              })}
            </div>
          </section>
          <SiteFooterReviewer
            questionnaireType={this.state.questionnaireType}
            dashboardLink={dashboardLink}
            completionPercentage={this.state.completionPercentage}
            groupSettings={{
              ...this.state.groupSettings,
              accountID: this.state.accountID,
              current360ID: this.state.current360ID,
              currentGroupID: this.state.currentGroupID,
            }}
          />

          <Modal
            isOpen={this.state.showSavedModal}
            contentLabel='Add a new 360'
            hideCloseButton={true}
            content={
              <div className='tc'>
                {
                  <div>
                    <h1>Your Goal has been saved successfully</h1>
                    <p>You can now close your tab/browser</p>
                  </div>
                }
              </div>
            }
          />
        </main>
      );
    } else {
      return <div className='vh-100 vw-100 flex items-center justify-center' />;
    }
  }
}

// answers={this.state.answers.Sections[key].Answers}

export default withBranding(authCondition)(SelfReview);
// export default SelfReview
