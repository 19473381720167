import React, { Component } from 'react';
import { db } from '../firebase';
import { Link } from 'react-router-dom';
import SiteHeader from '../components/site-header';
import SiteNav from '../components/site-nav';
import SiteFooterBrand from '../components/site-footer-brand';
import List360 from '../components/list360';
import withBranding from '../components/withbranding';
const authCondition = (authUser) => !!authUser;

class Landing extends Component {
  constructor() {
    super();

    this.state = {
      accountID: null,
      userID: null,
      my360IDs: null,
      questionnaireIDs: null,
      currentFeedback: null,
      submittedFeedback: null,
      previousFeedback: null,
      userLoaded: false,
    };
  }

  componentDidMount() {
    const {
      match: { params },
    } = this.props;
    const currentUserRef = db.doGetCurrentUser(params.accountID, params.userID);

    this.setState({
      accountID: params.accountID,
      userID: params.userID,
    });

    let activeFeedbackQuestionnaires = {};
    let noReviewersFeedbackQuestionnaires = {};
    let completedFeedbackQuestionnaires = {};
    let expiredFeedbackQuestionnaires = {};

    db.doGetCurrentUser(params.accountID, params.userID).then((snapshot) => {
      if (snapshot.data()) {
        let userObj = snapshot.data();
        console.log(userObj);
        console.log(params.accountID, params.userID);

        db.doGetCurrentUser360s(params.accountID, params.userID).onSnapshot((snapshot) => {
          const selfReviewsCount = snapshot.docs.length;
          if (selfReviewsCount > 0) {
            let threeSixtiesObj = {};
            snapshot.docs.map((doc, i) => {
              threeSixtiesObj[doc.id] = doc.data();
            });

            console.log(threeSixtiesObj);

            Object.keys(threeSixtiesObj).map((key) => {
              let current360ID = key;

              let groupID = threeSixtiesObj[key]['groupID'];
              let questionaireID = threeSixtiesObj[key]['questionnaireId'];

              db.getSelfReviewCompletionPercentage(
                params.accountID,
                params.userID,
                current360ID,
                groupID,
                questionaireID,
                'self-review'
              ).then((currentCompletionPercentage) => {
                console.log(currentCompletionPercentage);
                threeSixtiesObj[key]['completionPercentage'] = currentCompletionPercentage;

                db.get360Info(params.accountID, current360ID).then((snapshot) => {
                  let this360Info = snapshot.data();
                  console.log(this360Info);
                  if (this360Info) {
                    if (this360Info['type'] === '360') {
                      let oneDay = 24 * 60 * 60 * 1000;
                      let today = new Date();

                      db.getGroupDeadlineDate(params.accountID, current360ID, groupID).then((groupDeadlineDate) => {
                        // let twoWeeksFromCreationDate = new Date(this360Info['groups'][groupID]['createdDate'])
                        // twoWeeksFromCreationDate.setDate(twoWeeksFromCreationDate.getDate()+14)
                        // let deadlineDate = twoWeeksFromCreationDate

                        // console.log("groupDeadlineDate");
                        // console.log(groupDeadlineDate);
                        if (groupDeadlineDate) {
                          threeSixtiesObj[key]['groupDeadlineDate'] = groupDeadlineDate;

                          let deadlineDate = new Date(groupDeadlineDate);

                          if (deadlineDate > today) {
                            if (currentCompletionPercentage < 100) {
                              activeFeedbackQuestionnaires[key] = threeSixtiesObj[key];
                            } else {
                              completedFeedbackQuestionnaires[key] = threeSixtiesObj[key];
                            }
                          } else {
                            expiredFeedbackQuestionnaires[key] = threeSixtiesObj[key];
                          }
                        }
                        // else {
                        //   if(currentCompletionPercentage < 100) {
                        //     activeFeedbackQuestionnaires[key] = threeSixtiesObj[key]
                        //   } else {
                        //     completedFeedbackQuestionnaires[key] = threeSixtiesObj[key]
                        //   }
                        // }

                        console.log('activeFeedbackQuestionnaires');
                        console.log(activeFeedbackQuestionnaires);

                        this.setState({
                          selfReviewsCount: selfReviewsCount,
                          userDetails: userObj,
                          my360IDs: threeSixtiesObj,
                          activeFeedbackQuestionnaires: activeFeedbackQuestionnaires,
                          completedFeedbackQuestionnaires: completedFeedbackQuestionnaires,
                          expiredFeedbackQuestionnaires: expiredFeedbackQuestionnaires,
                          userLoaded: true,
                        });
                      });
                    }
                  } else {
                    console.log('sss');
                    // this.props.history.push(`/404/`)
                  }
                });
              });
              // let currentCompletionPercentage = threeSixtiesObj[key]['group'][ groupID ]['questionnaires'][ questionaireID ]['self-review']['completionPercentage']
            });
          } else {
            this.setState({
              userDetails: userObj,
              selfReviewsCount: selfReviewsCount,
              userLoaded: true,
            });
          }
        });

        // console.log(data.val()['360s'])
      } else {
        // If feedback page reviewer ID doesnt have a self-review:
        db.doGetCurrentReviewerRef(params.accountID, params.userID).then((snapshot) => {
          if (!snapshot.data()) {
            // If reviewer ID doesnt exist:
            this.props.history.push(`/404/`);
          } else {
            let reviewerDetails = snapshot.data();
            this.setState({
              userDetails: reviewerDetails,
              selfReviewsCount: 0,
              userLoaded: true,
            });

          }
        })
      }
    });
  }

  render() {
    const {
      match: { params },
    } = this.props;

    if (this.state.userLoaded) {
      return (
        <div>
          <SiteHeader userDetails={this.state.userDetails} completionPercentage={this.state.completionPercentage} />
          <SiteNav accountID={params.accountID} userID={params.userID} />
          <section className='welcome'>
            <div className='container container--slim'>
              <div className='welcome__row'>
                <h1>
                  <span>Welcome to your</span> personal feedback
                </h1>
              </div>
            </div>
          </section>
          {this.state.selfReviewsCount > 0 ? (
            <section className='page-wrapper'>
              <div className='container container--slim'>
                <p>This page displays all your live 360 Feedback and Goal Tracker projects.</p>
                <p>You can also see any historic projects that you have participated in.</p>
                {Object.keys(this.state.activeFeedbackQuestionnaires).length
                  ? Object.keys(this.state.activeFeedbackQuestionnaires)
                      .reverse()
                      .map((key) => {
                        let groupID = this.state.activeFeedbackQuestionnaires[key]['groupID'];
                        let questionaireID = this.state.activeFeedbackQuestionnaires[key]['questionnaireId'];
                        let completionPercentage = this.state.activeFeedbackQuestionnaires[key]['completionPercentage'];
                        return (
                          <List360
                            key={key}
                            index={key}
                            accountID={this.state.accountID}
                            questionaireID={questionaireID}
                            completionPercentage={completionPercentage}
                            groupID={groupID}
                          />
                        );
                      })
                  : ''}
              </div>

              <div className='container container--slim'>
                {Object.keys(this.state.completedFeedbackQuestionnaires).length
                  ? Object.keys(this.state.completedFeedbackQuestionnaires)
                      .reverse()
                      .map((key) => {
                        console.log(this.state.completedFeedbackQuestionnaires[key]);
                        let groupID = this.state.completedFeedbackQuestionnaires[key]['groupID'];
                        let questionaireID = this.state.completedFeedbackQuestionnaires[key]['questionnaireID'];
                        let completionPercentage = this.state.completedFeedbackQuestionnaires[key][
                          'completionPercentage'
                        ];
                        let deadlineDate = this.state.completedFeedbackQuestionnaires[key]['groupDeadlineDate'];
                        return (
                          <List360
                            key={key}
                            index={key}
                            accountID={this.state.accountID}
                            completionPercentage={completionPercentage}
                            groupID={groupID}
                            deadlineDate={deadlineDate}
                          />
                        );
                      })
                  : ''}
              </div>

              {Object.keys(this.state.expiredFeedbackQuestionnaires).length ? (
                <div className='container container--slim'>
                  <h2 className='mb0 mt4'>Previous Feedback</h2>
                  <p className='mb4 mt1'>View previous feedback</p>

                  {Object.keys(this.state.expiredFeedbackQuestionnaires)
                    .reverse()
                    .map((key) => {
                      let groupID = this.state.expiredFeedbackQuestionnaires[key]['groupID'];
                      let questionaireID = this.state.expiredFeedbackQuestionnaires[key]['questionaireId'];
                      let completionPercentage = this.state.expiredFeedbackQuestionnaires[key]['completionPercentage'];
                      let deadlineDate = this.state.expiredFeedbackQuestionnaires[key]['groupDeadlineDate'];
                      return (
                        <List360
                          key={key}
                          index={key}
                          accountID={this.state.accountID}
                          completionPercentage={completionPercentage}
                          deadlineDate={deadlineDate}
                          groupID={groupID}
                        />
                      );
                    })}
                </div>
              ) : (
                ''
              )}
            </section>
          ) : (
            <section className='page-wrapper vh-50 mb5'>
              <div className='pt5 pb5 tc welcome_row'>
                <h3>You currently have not been assigned a self-review</h3>
              </div>
            </section>
          )}

          <footer className='site-footer'>
            <div class='header-logo' />
          </footer>
        </div>
      );
    } else {
      return <div className='vh-100 vw-100 flex items-center justify-center'>Loading</div>;
    }
  }
}

export default withBranding(authCondition)(Landing);
