import React, { Component } from 'react'
import logo from '../logo.svg'

class SiteFooterBrand extends Component {
  render() {
    return (
      <footer className='site-footer site-footer--show'>
        <img src={logo} alt='Boomerang logo' />
      </footer>
    );
  }
}

export default SiteFooterBrand
