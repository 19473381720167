import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { db } from '../firebase';
import Modal from '../components/global/modal';

class SiteFooter extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showSubmitModal: false,
    };

    this.openPopupbox = this.openPopupbox.bind(this);
    this.closeSubmitModal = this.closeSubmitModal.bind(this);
  }
  componentDidMount() {
    if (this.props.completionPercentage == 100) {
      this.setState({
        showSubmitModal: true,
      });
    }
  }
  openPopupbox() {
    this.setState({
      showSubmitModal: true,
    });
  }
  closeSubmitModal() {
    this.setState({
      showSubmitModal: false,
    });
  }
  render() {
    return (
      <footer className='site-footer site-footer--show w-100 flex justify-center items-center pa3'>
        {this.props.questionnaireType == 'Goal Tracker' ? (
          <h2 className='white'>Boomerang</h2>
        ) : (
          <button className='btn btn--white' onClick={this.openPopupbox}>
            Submit Answers
          </button>
        )}

        <Modal
          isOpen={this.state.showSubmitModal}
          contentLabel='Add a new 360'
          hideCloseButton={false}
          onClose={() => this.closeSubmitModal()}
          content={
            <div className='tc'>
              {this.props.completionPercentage == 100 ? (
                <div>
                  <h1>You have successfully completed this review</h1>
                  {this.props.dashboardLink ? (
                    this.props.groupSettings !== undefined && this.props.groupSettings.locked === true ? (
                      <div>
                        <button className='btn btn--cancel mr2' onClick={(e) => this.setState({ showSubmitModal: false })}>
                          Edit Answers
                        </button>
                        <Link className='btn btn--back ml2' to={'../../../../'}>
                          <span>Account Dashboard</span>
                        </Link>
                      </div>
                    ) : (
                      <div>
                        <p>You can now setup reviewers</p>
                        <button className='btn btn--cancel mr2' onClick={(e) => this.setState({ showSubmitModal: false })}>
                          Edit Answers
                        </button>
                        <Link className='btn ml2' to={this.props.dashboardLink}>
                          Add Reviewers
                        </Link>
                      </div>
                    )
                  ) : (
                    <p>You can now close your tab/browser</p>
                  )}
                </div>
              ) : (
                <div>
                  <h1 className='red'>This review is not yet complete</h1>
                  <p>Please ensure you have answered all the rating scale and open text questions.</p>
                  <button className='btn btn--green' onClick={this.closeSubmitModal}>
                    Continue review
                  </button>
                </div>
              )}
            </div>
          }
        />
      </footer>
    );
  }
}

export default SiteFooter;
