import React, { Component } from 'react';
import { db } from '../firebase';
import { Link } from 'react-router-dom';
import SiteHeader from '../components/site-header';
import SiteNav from '../components/site-nav';
import SiteFooterBrand from '../components/site-footer-brand';
import Modal from '../components/global/modal';
import ReviewerInfo from '../components/reviewerinfo';
import List360 from '../components/list360';
import { IconCrossCircle, IconEditCircle } from '../svg/svgs';
import { convertRelationships, sleep, convertRelationshipTextToKey } from '../helpers';
import loadingGif from '../ripple.gif';
import withBranding from '../components/withbranding';
import axios from 'axios';
const authCondition = (authUser) => !!authUser;

class Reviewers extends Component {
  constructor(props) {
    super(props);

    this.changeStage = this.changeStage.bind(this);
    this.onSubmitReviewer = this.onSubmitReviewer.bind(this);
    this.deleteReviewer = this.deleteReviewer.bind(this);
    this.closeSendingModal = this.closeSendingModal.bind(this);
    this.confirmDeletion = this.confirmDeletion.bind(this);
    this.closeDeleteModal = this.closeDeleteModal.bind(this);
    this.sendReviewers = this.sendReviewers.bind(this);
    this.getCurrentUserReviewers = this.getCurrentUserReviewers.bind(this);
    this.state = {
      accountID: null,
      userLoaded: null,
      showSendingModal: false,
      showConfirmationModal: false,
      showUserDuplicateError: false,
      sendingError: false,
      sendingSuccess: false,
      sendingComplete: false,
      deleteModalOpen: false,
      reviewerRelationships: this.props.accountBranding.relationships,
      currentReviewerSet: Object.keys(this.props.accountBranding.relationships)[0],
      clientLogo: '',
    };
  }

  componentDidMount() {
    const {
      match: { params },
    } = this.props;

    db.doGetCurrentUser(params.accountID, params.userID).then((snapshot) => {
      this.setState({
        userDetails: snapshot.data(),
        accountID: params.accountID,
        current360ID: params.current360ID,
        groupID: params.groupID,
        userID: params.userID,
        clientLogo: this.props.accountBranding.logo,
      });

      // this.setState({ clientLogo: this.props.accountBranding.logo });
      // db.doGetClientLogo(params.accountID, params.current360ID).then((logo) => {
      //   this.setState({ clientLogo: logo });
      // })

      db.get360Info(params.accountID, params.current360ID).then((snapshot) => {
        this.setState({
          questionnaireID: snapshot.data()['questionnaireID'],
          current360Title: snapshot.data()['title'],
        });

        this.getCurrentUserReviewers();
      });
    });
  }

  getCurrentUserReviewers() {
    db.getCurrentUserReviewers(
      this.state.accountID,
      this.state.current360ID,
      this.state.groupID,
      this.state.userID,
      this.props.accountBranding.relationships
    ).onSnapshot((snapshot) => {
      let reviewers = {};
      let userReviewsEmails = []; // just map emails to this for easy checking.
      snapshot.docs.forEach((p) => {
        console.log(p.data());
      });
      Object.keys(this.props.accountBranding.relationships).map((key) => {
        reviewers[key] = {};
      });

      snapshot.docs.map((doc, i) => {
        if (reviewers[convertRelationshipTextToKey(doc.data()['relationship'])] !== undefined) {
          reviewers[convertRelationshipTextToKey(doc.data()['relationship'])][doc.id] = doc.data();
          console.log(reviewers[convertRelationshipTextToKey(doc.data()['relationship'])][doc.id]);
        }

        if (doc.data()['email']) {
          userReviewsEmails.push(doc.data()['email']);
        }
      });

      // Object
      //   .keys(userReviewersData)
      //   .map((reviewerRelationshipKey) => {
      //     Object
      //       .keys(userReviewersData[reviewerRelationshipKey])
      //       .map((individualReviewerKey) => {
      //         userReviewsEmails.push(userReviewersData[reviewerRelationshipKey][individualReviewerKey]['email'])
      //       })
      //   })

      // let userReviewersData = data
      //
      // let userReviewsEmails = [] // just map emails to this for easy checking.
      // if(userReviewersData) {
      //   // Loop through each group of reviewers by relationship (phew)
      //   Object
      //     .keys(userReviewersData)
      //     .map((reviewerRelationshipKey) => {
      //       Object
      //         .keys(userReviewersData[reviewerRelationshipKey])
      //         .map((individualReviewerKey) => {
      //           userReviewsEmails.push(userReviewersData[reviewerRelationshipKey][individualReviewerKey]['email'])
      //         })
      //     })
      // }
      //

      db.getCurrentGroupDetails(this.state.accountID, this.state.current360ID, this.state.groupID)
        .then((res) => {
          console.log(res.data());
          this.setState({
            userReviewers: reviewers,
            userReviewsEmails: userReviewsEmails,
            locked: res.data().locked,
            userLoaded: true,
          });
        })
        .catch((err) => {
          console.log(err);
          this.setState({
            userReviewers: reviewers,
            userReviewsEmails: userReviewsEmails,
            locked: true,
            userLoaded: true,
          });
        });
    });
  }

  closeSendingModal() {
    this.setState({
      showSendingModal: false,
      sendingSuccess: false,
      sendingError: false,
      sendingComplete: false,
    });
  }

  changeStage(newStage) {
    this.newReviewFormRef.reset();
    this.setState({
      currentReviewerSet: newStage,
    });
  }

  //update to axios call
  sendReviewers(e) {
    console.log('check');
    //-authenticated login token
    //-accountID(string)
    //-current360ID(string)
    //-currentGroupID(string)
    //-currentGroupUsers(object)
    //    (sending a single user needs to be formated as though there were multiple)
    //-curent360(object)
    //-currentGroupUserReviewers(object)
    //    (sending a single user needs to be formated as though there were multiple)

    let reviewersToSend = {};
    for (const initials in this.state.userReviewers) {
      if (Object.keys(this.state.userReviewers[initials]).length !== 0) {
        for (const uid in this.state.userReviewers[initials]) {
          if (this.state.userReviewers[initials][uid].completed === false && !this.state.userReviewers[initials][uid].emailRecieved) {
            if (!reviewersToSend[initials]) {
              reviewersToSend[initials] = {};
            }
            reviewersToSend[initials][uid] = this.state.userReviewers[initials][uid];
          }
        }
      }
    }

    console.log(reviewersToSend);

    let reqBody = {
      logo: this.props.accountBranding.logo,
      accountID: this.state.accountID,
      current360ID: this.state.current360ID,
      currentGroupID: this.state.groupID,
      currentUserReviewers: reviewersToSend,
      userDetails: this.state.userDetails,
    };

    console.log(reqBody);

    axios
      .post(
        '/revieweeCreated',
        {
          reqBody,
        },
        { timeout: 60000 }
      )
      .then((res) => {
        console.log(res.data.check);
        this.setState({
          showConfirmationModal: false,
          showSendingModal: true,
          sendingSuccess: true,
          sendingComplete: true,
        });
        for (const n in this.state.userReviewers) {
          for (const i in this.state.userReviewers[n]) {
            console.log(i);
            db.updateReviewerRecievedInfo(this.state.accountID, i, this.state.current360ID, this.state.groupID, this.state.userID);
          }
        }
      })
      .catch((err) => {
        console.log(err);
        if (err == 'Error: timeout of 60000ms exceeded') {
          this.setState({
            showConfirmationModal: false,
            showSendingModal: true,
            sendingSuccess: true,
            sendingComplete: true,
          });
          for (const n in this.state.userReviewers) {
            for (const i in this.state.userReviewers[n]) {
              console.log(i);
              db.updateReviewerRecievedInfo(this.state.accountID, i, this.state.current360ID, this.state.groupID, this.state.userID);
            }
          }
        } else {
          this.setState({
            sendingError: true,
            sendingComplete: true,
          });
        }
      });

    this.setState({
      showConfirmationModal: false,
      showSendingModal: true,
    });

    // let numberOfEmailsToSend = 0
    // let sentEmails = 0

    // Object.keys(this.state.reviewerRelationships)
    //   .map((key) => {
    //     let relationshipKey = key
    //     Object
    //       .keys(this.state.userReviewers[relationshipKey])
    //       .map((key) => {
    //         let reviewerID = key
    //         if(!this.state.userReviewers[relationshipKey][ reviewerID ]['emailRecieved']) {
    //           numberOfEmailsToSend++
    //         }
    //       })
    //     })

    // Object
    //   .keys(this.state.reviewerRelationships)
    //   .map((key, index) => {
    //     let relationshipKey = key
    //     Object
    //       .keys(this.state.userReviewers[relationshipKey])
    //       .map((key, index) => {

    //         sleep(( 2000 * numberOfEmailsToSend - 2000 * index )).then(() => { // BLEUGH! - this basically increases the timeout a few milliseconds seconds at a time to mimic async/await...

    //           let reviewerID = key
    //           if(!this.state.userReviewers[relationshipKey][ reviewerID ]['emailRecieved']) {
    //             // console.log("SEND EMAIL TO "+this.state.userReviewers[relationshipKey][ reviewerID ]['email']);

    //             window.emailjs.send('mailgun', process.env.REACT_APP_EMAILJS_TEMPLATEID, {
    //               senderEmail: "no-reply@boomerang-360.com",
    //               receiverEmail: this.state.userReviewers[relationshipKey][ reviewerID ]['email'],
    //               revieweeName: `${this.state.userDetails['forename']} ${this.state.userDetails['surname']}`,
    //               reviewerName: this.state.userReviewers[relationshipKey][ reviewerID ]['name'],
    //               reviewLink: `${process.env.REACT_APP_SELF_REVIEW_DOMAIN}/${this.state.accountID}/${this.state.current360ID}/review/${reviewerID}/`,
    //               current360Title: this.state.current360Title,
    //               clientLogo: this.state.clientLogo
    //             }).then(res => {
    //               console.log("SENT")

    //               sentEmails++

    //               db.updateReviewerRecievedInfo(this.state.accountID, reviewerID, this.state.current360ID, this.state.groupID, this.state.userID)

    //               if(sentEmails === numberOfEmailsToSend) {
    //                 this.setState({
    //                   sendingSuccess: true,
    //                   sendingComplete: true
    //                 })
    //               }

    //             }).catch(err => {
    //               this.setState({
    //                 sendingError: true,
    //                 sendingComplete: true
    //               })
    //             })

    //           }

    //         }); // end sleep

    //       })
    //     })
  }

  onSubmitReviewer(e) {
    // this.setState({
    //   showSendingModal: true
    // })
    e.preventDefault();

    let formRef = e.currentTarget;

    console.log(formRef);
    let fullname = `${this.state.userDetails.forename} ${this.state.userDetails.surname}`;
    let reviewerFullname = `${this.forename.value} ${this.surname.value}`;
    let reviewerEmail = this.email.value.toLocaleLowerCase();
    console.log(reviewerEmail);
    // let reviewerRelationship = this.relationship.value
    let reviewerRelationship = this.state.currentReviewerSet;

    console.log('this.state.userReviewsEmails.includes(reviewerEmail)');
    console.log(this.state.userReviewsEmails.includes(reviewerEmail));

    if (!this.state.userReviewsEmails.includes(reviewerEmail)) {
      db.createNewReviewer(
        this.state.accountID,
        fullname,
        reviewerFullname,
        reviewerEmail,
        reviewerRelationship,
        this.state.current360ID,
        this.state.groupID,
        this.state.questionnaireID,
        this.state.userID
      ).then((reviewerID) => {
        if (reviewerID) {
          db.assignReviewerToReviewee(
            this.state.accountID,
            reviewerID,
            reviewerFullname,
            this.state.current360ID,
            this.state.groupID,
            this.state.userID,
            reviewerRelationship,
            reviewerEmail
          ).then((data) => {
            formRef.reset();
          });
        }
      });
    } else {
      this.setState({
        showUserDuplicateError: true,
      });
    }
  }

  // sendSelfReviewEmail(templateId, senderEmail, receiverEmail, revieweeName, reviewerName, current360Title, reviewLink) {

  //   window.emailjs.send('mailgun', templateId, {
  //     senderEmail,
  //     receiverEmail: receiverEmail,
  //     revieweeName: revieweeName,
  //     reviewerName: reviewerName,
  //     reviewLink: reviewLink,
  //     current360Title: current360Title,
  //   }).then(res => {
  //     console.log("SENT");

  //     db.updateReviewerRecievedDate()

  //     db.getCurrentUserReviewers(this.state.current360ID, this.state.groupID, this.state.userID).then((data) => {
  //       this.setState({
  //         userReviewers: data
  //       })
  //     })

  //   })
  //   // Handle errors here however you like, or use a React error boundary
  //   .catch(err => alert('Failed to send notification. Error: ', err))
  // }

  deleteReviewer(reviewerIDtoDelete) {
    this.setState({
      deleteModalOpen: true,
      reviewerIDtoDelete: reviewerIDtoDelete,
    });
  }
  closeDeleteModal() {
    this.setState({
      deleteModalOpen: false,
      reviewerIDtoDelete: null,
    });
  }

  confirmDeletion() {
    db.doDeleteReviewerFromReviewee(this.state.accountID, this.state.reviewerIDtoDelete, this.state.current360ID, this.state.groupID, this.state.userID).then(
      () => {
        db.doDeleteReviewer(this.state.accountID, this.state.reviewerIDtoDelete);

        this.closeDeleteModal();
      }
    );

    // db.doDeleteReviewer(this.state.accountID, this.state.reviewerIDtoDelete).then((data) => {
    //
    //   alert("HOORAY!")
    //
    // })
  }

  render() {
    const {
      match: { params },
    } = this.props;

    if (this.state.userLoaded) {
      const returnLink = `/${params.accountID}/${this.state.userID}/360/${this.state.current360ID}/${this.state.groupID}/`;
      let nextStageKey = Object.keys(this.state.reviewerRelationships)[
        Object.keys(this.state.reviewerRelationships).indexOf(this.state.currentReviewerSet) + 1
      ];
      let prevStageKey = Object.keys(this.state.reviewerRelationships)[
        Object.keys(this.state.reviewerRelationships).indexOf(this.state.currentReviewerSet) - 1
      ];
      return (
        <main>
          <SiteHeader userDetails={this.state.userDetails} completionPercentage={this.state.completionPercentage} />
          <SiteNav accountID={params.accountID} userID={params.userID} />
          <section className='page-wrapper'>
            <div className='container container--slim'>
              <h1 className='mb0'>Add your reviewers</h1>
              <div className='flex justify-around'>
                {Object.keys(this.state.reviewerRelationships).map((key) => {
                  return (
                    <button
                      style={{ color: this.state.reviewerRelationships[key]['colour'] }}
                      onClick={() => {
                        this.setState({ currentReviewerSet: key });
                      }}
                      className={
                        key != this.state.currentReviewerSet
                          ? 'ph1 pv2 mt4 input-reset bn pointer dark-blue f4 fw7 br2 bg-transparent strong'
                          : 'underline ph1 pv2 mt4 input-reset bn pointer dark-blue f4 fw7 br2 bg-transparent strong'
                      }
                    >
                      {this.state.reviewerRelationships[key]['name']}
                    </button>
                  );
                })}
              </div>

              <div className='w100 bg-white shadow pa4 mv4'>
                <form onSubmit={this.onSubmitReviewer} ref={(el) => (this.newReviewFormRef = el)}>
                  <div className='w-70'>
                    <h2 className='near-black' style={{fontSize: '.8125rem', lineHeight: '1.8', marginBottom: '1.4em'}}>
                      <strong>Adding {this.state.reviewerRelationships[this.state.currentReviewerSet]['name']} Reviewers</strong>
                    </h2>
                    {this.state.reviewerRelationships[this.state.currentReviewerSet]['description'] ? (
                      <p>
                        <strong>{this.state.reviewerRelationships[this.state.currentReviewerSet]['description']}</strong>
                      </p>
                    ) : (
                      ''
                    )}
                    <p>Remember for 360's to remain anonmymous you should if possible add more than one reviewer.</p>
                  </div>
                  {Object.keys(this.state.userReviewers[this.state.currentReviewerSet]).length ? (
                    <div className='mv4'>
                      {Object.keys(this.state.userReviewers[this.state.currentReviewerSet])
                        .reverse()
                        .map((key) => {
                          return !this.state.userReviewers[this.state.currentReviewerSet][key]['emailRecieved'] &&
                            !this.state.userReviewers[this.state.currentReviewerSet][key]['completed'] ? (
                            <div key={key} index={key} className='flex items-center w-100 bg--pale pv3 ph4 mv2'>
                              <p className='w-30 ma0 pv1'>
                                <strong>{this.state.userReviewers[this.state.currentReviewerSet][key].name}</strong>
                              </p>
                              <p className='w-30 ma0 pv1 tc'>{this.state.userReviewers[this.state.currentReviewerSet][key].email}</p>
                              <p className='w-40 ma0 pv1 tr'>
                                <button className='ml4 has-tooltip' data-tooltip='Delete Reviewer' onClick={() => this.deleteReviewer(key)}>
                                  <IconCrossCircle width='30' height='30' fill='#FF0000' />
                                </button>
                              </p>
                            </div>
                          ) : (
                            ''
                          );
                        })}
                    </div>
                  ) : (
                    ''
                  )}

                  <div className='pv4'>
                    <hr />
                  </div>

                  <div className='flex justify-between ph4'>
                    <div className='w-30'>
                      <label htmlFor='forename' className='b db mb3'>
                        First Name
                      </label>
                      <input
                        required
                        ref={(input) => (this.forename = input)}
                        aria-describedby='forename-desc'
                        className='input-reset bn pa3 mb2 db w-100 bg-light-blue'
                        type='text'
                        placeholder='Type first name here'
                        id='forename'
                      />
                    </div>
                    <div className='w-30'>
                      <label htmlFor='surname' className='b db mb3'>
                        Last Name
                      </label>
                      <input
                        required
                        ref={(input) => (this.surname = input)}
                        aria-describedby='surname-desc'
                        className='input-reset bn pa3 mb2 db w-100 bg-light-blue'
                        type='text'
                        placeholder='Type last name here'
                        id='surname'
                      />
                    </div>
                    <div className='w-30'>
                      <label htmlFor='email' className='b db mb3'>
                        Email
                      </label>
                      <input
                        required
                        ref={(input) => (this.email = input)}
                        aria-describedby='email-desc'
                        className='input-reset bn pa3 mb2 db w-100 bg-light-blue'
                        type='email'
                        placeholder='Type email here'
                        id='email'
                      />
                    </div>
                  </div>

                  <div className='flex justify-end ph4'>
                    <div className='w-30'>
                      {this.state.locked ? (
                        <button disabled className='btn w-100' type='submit'>
                          Reviewers Locked
                        </button>
                      ) : (
                        <button className='btn w-100' type='submit'>
                          Add reviewer
                        </button>
                      )}
                    </div>
                  </div>
                </form>

                <div className='mt5'>
                  <hr />
                </div>

                <div className='flex justify-center pv4'>
                  {Object.keys(this.state.reviewerRelationships).indexOf(this.state.currentReviewerSet) > 0 ? (
                    <button onClick={() => this.changeStage(prevStageKey)} className='btn btn--back'>
                      <span>Back</span>
                    </button>
                  ) : (
                    ''
                  )}
                  {this.state.reviewerRelationships[nextStageKey] ? (
                    <button onClick={() => this.changeStage(nextStageKey)} className='btn ml-auto' style={{backgroundColor: '#137752'}}>
                      Next Stage - {this.state.reviewerRelationships[nextStageKey]['name']} Reviewers
                    </button>
                  ) : this.state.locked ? (
                    <button disabled className='btn ml-auto'>
                      Reviewers Locked
                    </button>
                  ) : (
                    <button
                      className='btn ml-auto'
                      onClick={() => {
                        this.setState({ showConfirmationModal: true });
                      }}
                    >
                      Send Email Notification to your reviewers
                    </button>
                  )}
                </div>
              </div>

              <div className='w100 bg-white shadow pa4 mv5'>
                <h2 className='near-black' style={{fontSize: '.8125rem', lineHeight: '1.8', marginBottom: '1.4em'}}>
                  <strong>Reviewer summary</strong>
                </h2>
                <p>The following users have recieved a notification email to complete your review.</p>
                {Object.keys(this.state.reviewerRelationships).map((key) => {
                  let relationshipKey = key;
                  let activeNotificationsCount = 0;

                  if (Object.keys(this.state.userReviewers[relationshipKey]).length) {
                    Object.keys(this.state.userReviewers[relationshipKey]).map((key) => {
                      if (this.state.userReviewers[relationshipKey][key]['emailRecieved'] || this.state.userReviewers[relationshipKey][key]['completed']) {
                        activeNotificationsCount++;
                      }
                    });
                  }

                  return (
                    <div className='pb3'>
                      <h3 className='f3'>{this.state.reviewerRelationships[relationshipKey]['name']}</h3>
                      {activeNotificationsCount > 0 ? (
                        <div className='mv4'>
                          <div className='flex w-100 ph4'>
                            <p className='w-20 ma0 pv1'>Name</p>
                            <p className='w-20 ma0 pv1 tc'>Review Status</p>
                            <p className='w-30 ma0 pv1 tc'>Email Address</p>
                            <p className='w-30 ma0 pv1 tc'>Actions</p>
                          </div>
                          {Object.keys(this.state.userReviewers[relationshipKey])
                            .reverse()
                            .map((key) => {
                              return this.state.userReviewers[relationshipKey][key]['emailRecieved'] ||
                                this.state.userReviewers[relationshipKey][key]['completed'] ? (
                                <ReviewerInfo
                                  key={key}
                                  accountID={this.state.accountID}
                                  reviewerID={key}
                                  relationshipKey={relationshipKey}
                                  userReviewers={this.state.userReviewers}
                                  name={this.state.userReviewers[relationshipKey][key].name}
                                  email={this.state.userReviewers[relationshipKey][key].email}
                                  groupID={this.state.groupID}
                                  current360ID={this.state.current360ID}
                                  userDetails={this.state.userDetails}
                                  logo={this.props.accountBranding.logo}
                                />
                              ) : (
                                ''
                              );
                            })}
                        </div>
                      ) : (
                        <p>No {this.state.reviewerRelationships[relationshipKey]['name']} reviewers setup.</p>
                      )}
                    </div>
                  );
                })}
              </div>

              <Link className='btn btn--back' to={returnLink}>
                <span>Return to my Dashboard</span>
              </Link>
            </div>
          </section>

          <footer className='site-footer'>
            <div className='header-logo' />
          </footer>

          <Modal
            isOpen={this.state.showSendingModal}
            contentLabel='Reviewer Confirmation'
            hideCloseButton={true}
            onClose={() => this.closeSendingModal()}
            content={
              <div className='tc'>
                {!this.state.sendingComplete ? <h1 className='mt0 mb2'>Sending Reviewer Notification</h1> : <h1 className='mt0 mb2'>Notification Complete</h1>}

                {!this.state.sendingComplete ? <div className='loading-spinner loading-spinner--lonely' /> : ''}
                {this.state.sendingError ? (
                  <p className='red'>There was an error assigning one or more Reviewers, please check Reviewer details and try again</p>
                ) : (
                  ''
                )}
                {this.state.sendingSuccess ? <p>An email has been sent to your selected Reviewers notifiying them to complete a 360 on your behalf.</p> : ''}
                {this.state.sendingComplete ? (
                  <button className='btn' onClick={this.closeSendingModal}>
                    Close
                  </button>
                ) : (
                  ''
                )}
              </div>
            }
          />

          <Modal
            isOpen={this.state.showConfirmationModal}
            contentLabel='Please Note'
            hideCloseButton={false}
            onClose={() => {
              this.setState({ showConfirmationModal: false });
            }}
            content={
              <div className='tc'>
                <p>
                  Note: For feedback to remain anonymous, you should add more than one reviewer in each "relationship group", if possible.Your reviewers will be
                  notified via email when you confirm below
                </p>
                <button onClick={this.sendReviewers} className='btn btn--green mt2'>
                  Proceed and send invitations to selected reviewers
                </button>
                <button
                  onClick={() => {
                    this.setState({ showConfirmationModal: false });
                  }}
                  className='btn mt4'
                >
                  Add more Reviewers +
                </button>
              </div>
            }
          />

          <Modal
            isOpen={this.state.showUserDuplicateError}
            contentLabel='Please Note'
            hideCloseButton={false}
            onClose={() => {
              this.setState({ showUserDuplicateError: false });
            }}
            content={
              <div className='tc'>
                <h3>Error</h3>
                <p>
                  You have already added this email address as a Reviewer.
                  <br />
                  Please ensure every Reviewer is unique
                </p>
                <button
                  onClick={() => {
                    this.setState({ showUserDuplicateError: false });
                  }}
                  className='btn mt3'
                >
                  Add more Reviewers +
                </button>
              </div>
            }
          />

          <Modal
            isOpen={this.state.deleteModalOpen}
            contentLabel='Confirm Deletion'
            hideCloseButton={true}
            onClose={() => {
              this.setState({ deleteModalOpen: false });
            }}
            content={
              <div className='tc'>
                <h3>Confirm Deletion</h3>
                <p>Do you really want to remove this reviewer?</p>
                <button
                  onClick={() => {
                    this.setState({ deleteModalOpen: false });
                  }}
                  className='btn btn--cancel mt3 mh2'
                >
                  Cancel
                </button>
                <button
                  onClick={() => {
                    this.confirmDeletion();
                  }}
                  className='btn btn--red mt3 mh2'
                >
                  <span>Delete</span>
                </button>
              </div>
            }
          />
        </main>
      );
    } else {
      return <div className='vh-100 vw-100 flex items-center justify-center'>Loading</div>;
    }
  }
}

export default withBranding(authCondition)(Reviewers);
