import React, { Component } from 'react'
import { db } from '../firebase'
import { Link } from 'react-router-dom'
import moment from 'moment'

class ListReview extends Component {
  constructor() {
    super()
    // const { reviewInfo } = this.props
  }
  render() {
    const completeReviewLink = `/${this.props.accountID}/${ this.props.reviewInfo.current360ID }/review/${ this.props.index }/`
    return (
      <div className="flex-ns justify-between w100 bg-white shadow-2 ph4 pv2 pv4 mv4">
        <div className="w-60-ns">
          <p className="mv2 mv1-ns tc tl-ns b">{ this.props.reviewInfo.currentRevieweeName }</p>
          <p className="mv2 mv1-ns tc tl-ns"><strong>{ (this.props.reviewInfo.completionPercentage > 0) ? this.props.reviewInfo.completionPercentage : 0 }%</strong> complete</p>
          {
            (this.props.reviewInfo.recievedDate) ? (
              <p className="mv2 mv1-ns tc tl-ns">Request recieved on <strong>{ moment(this.props.reviewInfo.recievedDate).format("DD/MM/YY") }</strong></p>
            ) : ''
          }
        </div>
        <div className="w-40-ns">
          <p className="mv2 ma0-ns tc tr-ns"><Link className="btn" to={ completeReviewLink }><span>{ (this.props.reviewInfo.completionPercentage == 100) ? 'View' : 'Complete' } Review</span></Link></p>
        </div>
      </div>
    )
  }
}
//

export default ListReview
