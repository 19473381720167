import React from 'react';

export const IconEditCircle = (props) => {
  // const umbrellafill = props.umbrellafill || 'orangered'
  const { height, width, fill } = props;

  return (
    <svg className="cog" xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 25 25" aria-labelledby="title">
      <title id="title">Home</title>
      <g>
      <path fill={fill} d="M12.5,0C5.6,0,0,5.6,0,12.5S5.6,25,12.5,25S25,19.4,25,12.5S19.4,0,12.5,0z M9.8,12.1C9.8,12,9.8,12,9.8,12.1
      	C9.8,12,9.8,12,9.8,12.1l3.6-8.7c0.1-0.3,0.4-0.4,0.7-0.3c0.3,0.1,0.4,0.4,0.3,0.7l-3.6,8.5l0,2.7l1.9-1.9l3.6-8.5
      	c0.1-0.3,0.4-0.4,0.7-0.3c0.3,0.1,0.4,0.4,0.3,0.7l-3.6,8.6c0,0.1-0.1,0.1-0.1,0.2l-2.8,2.8c-0.1,0.1-0.2,0.1-0.3,0.1
      	c-0.1,0-0.1,0-0.2,0c-0.2-0.1-0.3-0.3-0.3-0.5l0-4C9.8,12.2,9.8,12.1,9.8,12.1z M17,19.8H8c-0.3,0-0.5-0.2-0.5-0.5s0.2-0.5,0.5-0.5
      	H17c0.3,0,0.5,0.2,0.5,0.5S17.3,19.8,17,19.8z"/>
      </g>
    </svg>
  )
}


export const IconCrossCircle = (props) => {
  // const umbrellafill = props.umbrellafill || 'orangered'
  const { height, width, fill } = props;

  return (
    <svg className="cog" xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 25 25" aria-labelledby="title">
      <title id="title">Cross</title>
      <g>
      <path fill={fill} d="M12.5,0C5.6,0,0,5.6,0,12.5S5.6,25,12.5,25S25,19.4,25,12.5S19.4,0,12.5,0z M17.1,16.3c0.2,0.2,0.2,0.5,0,0.7
	c-0.1,0.1-0.2,0.1-0.4,0.1s-0.3,0-0.4-0.1l-3.8-3.8L8.9,17c-0.1,0.1-0.2,0.1-0.4,0.1s-0.3,0-0.4-0.1c-0.2-0.2-0.2-0.5,0-0.7l3.8-3.8
	L8.2,8.7C8,8.5,8,8.2,8.2,8s0.5-0.2,0.7,0l3.8,3.8L16.4,8c0.2-0.2,0.5-0.2,0.7,0s0.2,0.5,0,0.7l-3.8,3.8L17.1,16.3z"/>
      </g>
    </svg>
  )
}
